import * as React from 'react';
import { BubbleDataPoint, Chart, ChartTypeRegistry, Point, registerables} from 'chart.js';
import {Component, useEffect, useState} from "react";

Chart.register(...registerables);

export interface ChartProps {
  jsonString: string
}

const options = (label: string, type: string) => {
  let data = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      tooltips: {
        enabled: false
      },
      title: {
        display: false,
        text: label
      }
    },
    scales: {}
  }

  if (type == 'stacked-horizontal') {
    data.plugins.legend.display = false
    data.scales = {
      x: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    }
  }

  if (type == 'stacked') {
    data.scales = {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }

  return data;
}

const colorSet =(type: string, data_index: number, dataset: any) => {
  let colors: string[] = [];

  if (type == 'pie') {
    colors = []
    for (let i = 0; i < dataset.length.times; i++) {
      colors.push("rgba(#{rand(50..255)}, #{rand(50..255)}, #{rand(50..255)}, 1)")
    }

    return colors;
  }

  if (data_index == 1) {
    colors.push('#17b6d4')
  } else if (data_index == 2) {
    colors.push('#eab30a')
  } else {
    colors.push('#f97317')
  }

  return colors;
}

const chartData = (type: string, labels: any, datasets: any, label: string) => {
  let data_index = 0
  const corrected_type = (type == 'stacked' || type == 'stacked-horizontal') ? 'bar' : type

  const data = {
    data: {
      labels: labels,
      datasets: datasets.map((ds: any) => {
        data_index += 1
        return {
          type: ds.type ? ds.type : corrected_type,
          label: ds.label,
          data: ds.data,
          backgroundColor: ds.background ? ds.background : colorSet(type, data_index, ds.data),
          borderColor: type == 'line' ? ds.border ? ds.border : colorSet(type, data_index, ds.data) : '#00000000',
          pointStyle: 'cross'
        }
      })
    },
    options: options(label, type)
  }

  return data;
}

type ChartState = {
  data: string,
  myChart: any
}

export class ChartComponent extends Component<{}, ChartState> {
  chartRef: any

  constructor(props: ChartProps) {
    super(props);

    this.chartRef = React.createRef();

    this.state = {
      data: props.jsonString,
      myChart: null
    };
  }

  componentDidMount() {
    // @ts-ignore
    const jsonData = JSON.parse(this.state.data)
    const data = chartData(jsonData.type, jsonData.labels, jsonData.datasets, jsonData.label)

    const node = this.chartRef.current as HTMLCanvasElement;
    const myChart = new Chart(node, data);
    this.setState({
      myChart: myChart,
      data: this.state.data
    });
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    if (this.state.myChart) {
      this.state.myChart.destroy
    }
  }

  render() {
    return <div className="chard relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-start justify-between space-x-3 hover:border-gray-400 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <canvas ref={this.chartRef as React.RefObject<HTMLCanvasElement>}></canvas>
    </div>
  }
}
