import * as React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Button, Appearance } from './Button';
import {BotUsersCheckboxList} from "./whatsapp-broadcast-fields/bot-user-input";
import {useTranslation} from "react-i18next";
import { DepartmentManageShopCompaniesFormQuery, DepartmentManageShopCompaniesFormQuery_company_departmentShopCompanies } from '../__generated__/DepartmentManageShopCompaniesFormQuery';
import { useForm } from 'react-hook-form';
import { CheckboxList } from './department-manage-shop-fields/list-input';

export interface DepartmentManageShopCompaniesValues {
  selectedIds: string[]
}

export const DepartmentManageShopCompaniesForm: ({companyId, onCancel, onSubmit}: {
  departmentId: string | undefined;
  companyId: string;
  onCancel: any;
  onSubmit: any
}) => JSX.Element = ({ companyId, departmentId, onCancel, onSubmit }) => {
  const { control, register, handleSubmit, formState, watch, setValue, getValues } = useForm<DepartmentManageShopCompaniesValues>({ defaultValues: { selectedIds: [] } })
  const { t, i18n } = useTranslation();

  // need to watch, otherwise views wont update
  const selectedIds = watch("selectedIds");
 
  const [getLinkedCompanies, { data, loading, error }] = useLazyQuery<DepartmentManageShopCompaniesFormQuery>(gql`
    query DepartmentManageShopCompaniesFormQuery($companyId: ID!, $departmentId: ID!) {
      company(id: $companyId) {
        id
        departmentShopCompanies(departmentId: $departmentId) {
          id
          name
          companyId
          departmentId
          attached
        }
      }
    }
  `, {
    fetchPolicy: 'network-only',
  })

  React.useEffect(() => {
    getLinkedCompanies({ variables: { companyId, departmentId } }).then((response)=> {
      const responseData = response?.data?.company?.departmentShopCompanies
      console.log(responseData?.map(x => x.name));
      if (responseData) {      
        const selected = responseData!.filter((x) => x!.attached == true).map((x) => x!.companyId!);
        if (selected) {
          setValue('selectedIds', selected!)
        }
      }
    });
  }, [])

  if (!data?.company?.departmentShopCompanies){
    return <></>;
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <CheckboxList label={t('companies')!} items={data!.company!.departmentShopCompanies} getValues={getValues} setValue={setValue} {...register('selectedIds')} errors={formState.errors}>
        </CheckboxList>
      </div>

      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('send')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default DepartmentManageShopCompaniesForm
