import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  QaModulesCreateRoute_CreateQaModuleMutation,
  QaModulesCreateRoute_CreateQaModuleMutationVariables
} from '../__generated__/QaModulesCreateRoute_CreateQaModuleMutation';
import { QaModulesCreateRouteQuery } from '../__generated__/QaModulesCreateRouteQuery';
import { BotModuleForm, BotModuleValues } from '../components/BotModuleForm';
import { SlideOver } from '../components/SlideOver';
import {debug} from "util";
import {TextInputField} from "../components/text-input-field";
import {SelectField} from "../components/select-field";
import {Appearance, Button} from "../components/Button";
import {QaModuleForm, QaModuleValues} from "../components/QaModuleForm";
import {useTranslation} from "react-i18next";

interface QaModulesCreateRouteProps {
  companyId: string
}

const QaModulesCreateRoute: React.FC<QaModulesCreateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const folderId = searchParams.get('folder')
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery<QaModulesCreateRouteQuery>(gql`
    query QaModulesCreateRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
      }
    }
  `, {
    variables: {
      companyId
    }
  });

  const [createBotModule] = useMutation<QaModulesCreateRoute_CreateQaModuleMutation, QaModulesCreateRoute_CreateQaModuleMutationVariables>(gql`
    mutation QaModulesCreateRoute_CreateQaModuleMutation($input: CreateQaModuleMutationInput!) {
      createQaModule(input: $input) {
        qaModule {
          id
          name
          tagList
        }
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/qa_modules`)
  }

  async function onSubmit(values: QaModuleValues) {
    let { data } = await createBotModule({ variables: { input: { companyId, ...values } } })

    navigate(`/${companyId}/qa_modules`)
  }

  return (
    <SlideOver title={t('create_q_a_module')} onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (
        <QaModuleForm
          qaModule={{ name: '', tags: '' }}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </SlideOver>
  )
}

export default QaModulesCreateRoute;
