import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BotModulesCreateRouteQuery } from '../__generated__/BotModulesCreateRouteQuery';
import { BotModuleForm, BotModuleValues } from '../components/BotModuleForm';
import { SlideOver } from '../components/SlideOver';
import {loadBotModulesForCompany, makeCreateBotModuleMutation} from "../queries/BotModulesQuerieHandler";
import {useTranslation} from "react-i18next";

export interface BotModulesCreateRouteProps {
  companyId: string
}

const BotModulesCreateRoute: React.FC<BotModulesCreateRouteProps> = ({ companyId }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const folderId = searchParams.get('folder')

  const { data, loading, error } = loadBotModulesForCompany(companyId as string)
  const [createBotModule] = makeCreateBotModuleMutation();

  function onCancel() {
    navigate(folderId
      ? `/${companyId}/bot_modules?folder=${folderId}`
      : `/${companyId}/bot_modules`
    )
  }

  async function onSubmit(values: BotModuleValues) {
    let { data } = await createBotModule({ variables: { input: { companyId, ...values } } })

    navigate(data!.createBotModule!.botModule.folderId
      ? `/${companyId}/bot_modules?folder=${data!.createBotModule!.botModule.folderId}`
      : `/${companyId}/bot_modules`
    )
  }

  return (
    <SlideOver title={t('create_bot_module')}  onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (
        <BotModuleForm
          botModule={{ folderId: folderId ?? undefined }}
          folders={data!.company!.folders}
          themes={data!.company!.themes}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </SlideOver>
  )
}

export default BotModulesCreateRoute;
