import * as React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useId } from '@reach/auto-id';
import { FormField, FormFieldProps } from './FormField';
import { TextInput } from './text-input-field';

export interface DatePickerProps extends Omit<ReactDatePickerProps, 'customInput'> {
  id?: string;
}

export const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
  return (
    <ReactDatePicker customInput={<TextInput ref={ref} />} {...props} />
  )
})

export interface DatePickerFieldProps extends Omit<FormFieldProps, 'labelFor'>, DatePickerProps {
  name: string
}

export const DatePickerField = React.forwardRef<HTMLInputElement, DatePickerFieldProps>((props, ref) => {
  const id = useId(props.id);
  const { id: unusedId, className, label, ...rest } = props

  return (
    <FormField className={className} label={label} labelFor={id} name={props.name} errors={props.errors}>
      <DatePicker ref={ref} id={id} {...rest} />
    </FormField>
  )
})
