import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { DepartmentsUpdateRoute_UpdateDepartmentMutation, DepartmentsUpdateRoute_UpdateDepartmentMutationVariables } from '../__generated__/DepartmentsUpdateRoute_UpdateDepartmentMutation';
import { DepartmentsUpdateRouteQuery } from '../__generated__/DepartmentsUpdateRouteQuery';
import { DepartmentForm, DepartmentValues } from '../components/DepartmentForm';
import { SlideOver } from '../components/SlideOver';
import {useTranslation} from "react-i18next";
import {useState} from "react";

interface DepartmentsUpdateRouteProps {
  companyId: string;
  userRole: string | null
}

const DepartmentsUpdateRoute: React.FC<DepartmentsUpdateRouteProps> = ({ userRole, companyId }) => {
  const navigate = useNavigate()
  const { departmentId } = useParams<'departmentId'>();
  const { t, i18n } = useTranslation();
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)

  const { data, loading, error } = useQuery<DepartmentsUpdateRouteQuery>(gql`
    query DepartmentsUpdateRouteQuery($departmentId: ID!) {
      department(id: $departmentId) {
        id
        companyId
        whatsappBroadcastTemplateId
        ...DepartmentForm_department
        company {
          whatsappBroadcastTemplates {
            id
            name
            displayName
            language
            body
            header
          }
        }
      }
    }

    ${DepartmentForm.fragments.department}
  `, {
    variables: {
      departmentId: departmentId as string
    }
  });

  const [updateDepartment] = useMutation<DepartmentsUpdateRoute_UpdateDepartmentMutation, DepartmentsUpdateRoute_UpdateDepartmentMutationVariables>(gql`
    mutation DepartmentsUpdateRoute_UpdateDepartmentMutation($input: UpdateDepartmentMutationInput!) {
      updateDepartment(input: $input) {
        department {
          id
          name
          kind
          closureMessage
          showQaModules
          language
          whatsappBroadcastTemplateId
          price
          timeZoneName
          description
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'DepartmentsUpdateRouteQuery',
      'DepartmentsOverviewRouteQuery'
    ]
  })

  function onCancel() {
    navigate(`/${companyId}/departments`)
  }

  async function onSubmit(values: DepartmentValues) {
    if(window.confirm(t('are_you_sure_bot_user_department'))) {
      let { data } = await updateDepartment({ variables: { input: { id: departmentId as string, ...values } } })
      if (data?.updateDepartment?.errors.length == 0) {
        navigate(`/${companyId}/departments`)
      } else {
        setMutationErrors(data?.updateDepartment?.errors!)
      }
    }
  }

  if (error){
    debugger
  }
  return (
    <SlideOver title={t('edit_department')} onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (!data || !data.department || data.department.companyId !== companyId) ? (
        <p>{t('not_found')}</p>
      ) : (
        <DepartmentForm
          userRole={userRole}
          department={data.department}
          whatsappBroadcastTemplates={data!.department.company!.whatsappBroadcastTemplates}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
      {mutationErrors && mutationErrors.length > 0 &&
          <div className="mt-4 bottom-10 px-5 py-4 bg-red-500 bg-white drop-shadow-lg">
            <p className="text-sm text-white mb-0 pb-0">
              <span className="mr-2 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
              {mutationErrors.join(', ')}
            </p>
          </div>
      }
    </SlideOver>
  )
}

export default DepartmentsUpdateRoute;
