import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { VFCwF } from '../types';
import { Tab, TabProps } from '@reach/tabs';
import { Tabs, TabList, TabPanels, TabPanel } from "@reach/tabs"
import { CardFormTab } from '../components/CardFormTabs';
import QuestionAnalyticsTable from '../components/QuestionAnalyticsTable';
import BotUserAnalyticsTable from '../components/BotUserAnalyticsTable';
import {useTranslation} from "react-i18next";

interface QuestionAnalyticsRouteProps {
  companyId: string
  userRole: string
}

const QuestionAnalyticsRoute: React.VFC<QuestionAnalyticsRouteProps> = ({ userRole, companyId }) => {
  const [ tabIndex, setTabIndex ] = React.useState<number>(0);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const views = [t('questions'), t('bot_users')]

  function handleFilterChange(event: React.ChangeEvent<HTMLSelectElement>, model: string) {
    if (event.target.value) {
      setSearchParams({ [model]: event.target.value });
    } else {
      searchParams.delete(model);
      setSearchParams(searchParams);
    }
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between h-10">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            {t('question_analytics')}
          </h1>
          {(userRole == 'admin' || userRole == 'superadmin') &&
            <a href={"/" + companyId + "/new_report"} className="inline-flex items-center border shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 disabled:bg-blue-300 disabled:hover:bg-blue-200 px-4 py-2 text-sm rounded-md">
              {t('report')}
            </a>
          }
        </div>
      </header>

      <main>
        <Tabs key={views.length} index={tabIndex} onChange={index => setTabIndex(index)}>
          <div className="border-b border-gray-200 flex justify-between">
            <TabList className="bg-transparent -mb-px space-x-4 h-14">
              {views.map((view, idx) => (
                <CardFormTab key={view} className="min-w-[3rem]">
                  {view}
                </CardFormTab>
              ))}
            </TabList>
          </div>

          <TabPanels className="mt-6">
            <TabPanel key="Questions">
              <QuestionAnalyticsTable
                companyId={companyId}
                beforeCursor={searchParams.get('before')}
                afterCursor={searchParams.get('after')}
                botModuleId={searchParams.get('botModule')}
                handleCursorChange={setSearchParams}
                onBotModuleChange={handleFilterChange}
              />
            </TabPanel>
            <TabPanel key="Bot Users">
              <BotUserAnalyticsTable
                companyId={companyId}
                beforeCursor={searchParams.get('before')}
                afterCursor={searchParams.get('after')}
                departmentId={searchParams.get('department')}
                handleCursorChange={setSearchParams}
                onDepartmentChange={handleFilterChange}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </main>
    </div>
  )
}

export default QuestionAnalyticsRoute;
