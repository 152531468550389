import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { SlideOver } from '../components/SlideOver';
import { BotUserForm, BotUserValues } from '../components/BotUserForm';
import {loadCreateBotUser, makeCreateBotUserMutation} from "../queries/BotUsersQueryHandler";
import {useState} from "react";
import {useTranslation} from "react-i18next";

interface BotUserCreateRouteProps {
	companyId: string;
}

const BotUsersCreateRoute: React.FC<BotUserCreateRouteProps> = ({ companyId }) => {
	const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const initialMutationErrors: string[] = [];
  const { t, i18n } = useTranslation();

  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)
  const departmentId = searchParams.get('department')

  const { data, loading, error } = loadCreateBotUser(companyId)

	const [createBotUser] = makeCreateBotUserMutation();

  function onCancel() {
		departmentId ?
			navigate(`/${companyId}/bot_users?department=${departmentId}`) :
			navigate(`/${companyId}/bot_users`)
  }

  async function onSubmit(values: BotUserValues) {
    let {data} = await createBotUser({variables: {input: {companyId, ...values}}})
    setMutationErrors(data.createBotUser.errors)

    if (data.createBotUser.errors.length == 0) {
      const botUser = data.createBotUser.botUser
      navigate({
        pathname: `/${companyId}/bot_users`,
        search: createSearchParams({department: botUser.departmentId, highlight: botUser.id}).toString()
      }, {replace: true})
    }
  }

	return (
		<SlideOver title={t('create_bot_user')} onClose={onCancel}>
			{data?.company.departments &&
        <BotUserForm
          companyId={companyId}
          useSteps={true}
          departments={data?.company.departments}
          departmentId={departmentId}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
      {mutationErrors && mutationErrors.length > 0 &&
        <div className="bottom-10 px-5 mt-4 py-4 bg-red-500 bg-white drop-shadow-lg">
          <div className="text-sm text-white">
            <span className="mr-4 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
            {mutationErrors.join(', ')}
          </div>
        </div>
      }
		</SlideOver>
	)
}

export default BotUsersCreateRoute;
