import * as React from 'react'
import { FileInputField, FileInputFieldProps } from './FileInputField'
import {useTranslation} from "react-i18next";

const ALLOWED_FORMATS = ["video/mp4", "video/webm", "video/ogg"]

export const videoTypeTest = {
	name: 'fileFormat',
	message: `Video must be any of type: ${ALLOWED_FORMATS.join(", ")}`,
	test: (value: FileList|null|string) => {
		if (!value || typeof value === "string") return true;
		return value instanceof FileList && ALLOWED_FORMATS.includes(value?.[0]?.type)
	}
}
export const videoSizeTest = {
	name: 'fileSize',
	message: "Video must be smaller than 16MB",
	test: (value: FileList|null|string) => {
		if (!value || typeof value === "string") return true;
		return value instanceof FileList && value?.[0]?.size <= 16777216
	}
}

export interface VideoInputFieldProps extends FileInputFieldProps {
	label: string
}

export const VideoInputField = React.forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  return (
		<FileInputField
			ref={ref}
			label={t('video')}
			accept={ALLOWED_FORMATS.join(", ")}
			inputhint="MP4 up to 16MB"
			{...props}
		/>
	)
})
