import * as React from 'react'
import { useId } from '@reach/auto-id'
import {ErrorMessage} from "@hookform/error-message";
import {WhatsappBroadcastFormQuery_company_whatsappBotUsers} from "../../__generated__/WhatsappBroadcastFormQuery";
import {useTranslation} from "react-i18next";
import {useState} from "react";

export interface BotUserCheckboxListProps {
  name: string
  setValue: any
  getValues: any
  id?: string
  className?: string
  label?: string
  errors?: any
  children: any
  style?: any
  whatsappBotUsers?: WhatsappBroadcastFormQuery_company_whatsappBotUsers
}

export const BotUsersCheckboxList = React.forwardRef<HTMLInputElement, BotUserCheckboxListProps>((props, ref) => {
  const [botUserFilter, setBotUserFilter] = useState("");
  const id = useId(props.id);
  const { id: unusedId, setValue, getValues, className, style, label, name, errors, whatsappBotUsers, onChange } = props
  const { t, i18n } = useTranslation();

  let botUserIds = (whatsappBotUsers?.edges || []).map(edge => edge!.node!.id);
  let selectedBotUserIds = Array.from(getValues("botUserIds") || [])

  const toggleAllBotUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (botUserIds.length == selectedBotUserIds.length){
      selectedBotUserIds = ['']
      setValue("botUserIds", selectedBotUserIds)
      onChange(event)
    } else {
      selectedBotUserIds = botUserIds
      setValue("botUserIds", selectedBotUserIds)
      onChange(event)
    }
  }

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newId = event.currentTarget.value;

    let selectedBotUserIds = Array.from(getValues("botUserIds") || [])
    if(!selectedBotUserIds.includes(newId)){
      selectedBotUserIds.push(newId);
    }else{
      selectedBotUserIds.splice(selectedBotUserIds.indexOf(newId), 1);  //deleting
    }

    setValue("botUserIds", selectedBotUserIds.length > 0 ? selectedBotUserIds : ['']);
    onChange(event)
  };

  const totalBotUsers = whatsappBotUsers?.edges || []
  const filteredBotUsers = totalBotUsers.filter(x => botUserFilter == '' || x?.node?.firstName.toLowerCase().includes(botUserFilter.toLowerCase() || x?.node?.lastName.toLowerCase().includes(botUserFilter.toLowerCase())))

  return (
    <div ref={ref} className="" style={style}>
      <label className="block font-medium" htmlFor={id}>
        {label}
      </label>
      <input type="hidden"/>
      {totalBotUsers.length > 0 && (
        <>
          <div className="my-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="43">
              {t('search')}
              <span> ({t('selected')}: </span>
              <span>{selectedBotUserIds?.filter(x => x.length > 0)?.length})</span>
            </label>
            <input type="search" value={botUserFilter} name="filterbotusers" onChange={e => setBotUserFilter(e.target.value)} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:number-sm border-gray-300 rounded-md"/>
          </div>
          <div ref={ref} className="block w-full pl-3 pr-4 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border" style={style}>
            <div className="mb-3">
              {filteredBotUsers.length > 0 &&
                <label className="flex w-full">
                  <span className="flex-1">{t('toggle_all_bot_users')}</span>
                  <input type="checkbox" checked={botUserIds.length == selectedBotUserIds.length}
                         onChange={toggleAllBotUser}>
                  </input>
                </label>
              }
            </div>
            <div>
              {filteredBotUsers.map(edge => (
                <div key={edge!.node!.id}>
                  <label className="flex w-full">
                    <span className="flex-1 inline-tags">
                      <span className="label">{edge!.node!.firstName} {edge!.node!.lastName}</span>
                      <span className="tag">
                        <span>{edge!.node!.botUserType} </span>
                      </span>
                    </span>
                    {!edge.node.anonymous &&
                      <input type="checkbox" name={edge!.node!.id} checked={selectedBotUserIds.includes(edge!.node!.id)} onChange={(event)=> onChangeSelect(event)} key={edge!.node!.id} value={edge!.node!.id}>
                      </input>
                    }
                  </label>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <ErrorMessage errors={errors} className="mt-2 text-sm text-red-600" name={name} as="p" />
    </div>
  )
})
