import React from 'react'
import '../translations/i18n';
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import Appsignal from '@appsignal/javascript'
import { ErrorBoundary } from '@appsignal/react'
import { createClient } from '../apollo'
import RootRoute from '../routes/RootRoute'

let appsignal = new Appsignal({
  key: process.env.APPSIGNAL_FRONTEND_KEY,
  revision: process.env.HEROKU_SLUG_COMMIT,
})

let csrfToken = document.querySelector('meta[name=csrf-token]')!.getAttribute('content') as string
let client = createClient(csrfToken)

let container = document.getElementById('root')

if (container) {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary instance={appsignal}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <RootRoute />
          </BrowserRouter>
        </ApolloProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    container
  )
}
