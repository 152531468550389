import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ThemesUpdateRoute_UpdateThemeMutation, ThemesUpdateRoute_UpdateThemeMutationVariables } from '../__generated__/ThemesUpdateRoute_UpdateThemeMutation';
import { ThemesUpdateRouteQuery } from '../__generated__/ThemesUpdateRouteQuery';
import { SlideOver } from '../components/SlideOver';
import { ThemeForm, ThemeValues } from '../components/ThemeForm';
import {useTranslation} from "react-i18next";

interface ThemesUpdateRouteProps {
  companyId: string;
}

const ThemesUpdateRoute: React.VFC<ThemesUpdateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { themeId } = useParams<'themeId'>();
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery<ThemesUpdateRouteQuery>(gql`
    query ThemesUpdateRouteQuery($themeId: ID!) {
      theme(id: $themeId) {
        id
        companyId
        ...ThemeForm_theme
      }
    }

    ${ThemeForm.fragments.theme}
  `, {
    variables: {
      themeId: themeId as string
    }
  });

  const [updateTheme] = useMutation<ThemesUpdateRoute_UpdateThemeMutation, ThemesUpdateRoute_UpdateThemeMutationVariables>(gql`
    mutation ThemesUpdateRoute_UpdateThemeMutation($input: UpdateThemeMutationInput!) {
      updateTheme(input: $input) {
        theme {
          id
        }
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/themes`)
  }

  async function onSubmit(values: ThemeValues) {
    await updateTheme({ variables: { input: { id: themeId as string, ...values } } })
    navigate(`/${companyId}/themes`)
  }

  return (
    <SlideOver title={t('edit_theme')} onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (!data || !data.theme || data.theme.companyId !== companyId) ? (
        <p>{t('not_found')}</p>
      ) : (
        <ThemeForm
          theme={data.theme}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </SlideOver>
  )
}

export default ThemesUpdateRoute;
