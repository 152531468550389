import * as React from "react"
import { Tab, TabProps } from "@reach/tabs"
import cx from "clsx"

export interface CardFormTab extends TabProps {
  className?: string
  // `isSelected` comes from `TabList` cloning the `CardFormTab`.
  isSelected?: boolean
}

export const CardFormTab: React.FC<CardFormTab> = props => {
  const { children, className, ...tabProps } = props
  const { isSelected } = tabProps;

  return (
    <Tab
      className={cx(className, "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm", isSelected ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300")}
      {...tabProps}
    >
      {children}
    </Tab>
  );
}
