import * as React from 'react'
import { ApolloError, gql, useQuery } from '@apollo/client'
import cx from 'clsx'
import { CardType } from '../../__generated__/globalTypes'
import { BaseFormProps } from './base-form'
import { ImageForm, ImageFormFragment, defaultValuesForImageCard } from './image-form'
import { OpenForm, OpenFormFragment, defaultValuesForOpenCard } from './open-form'
import { QuizForm, QuizFormFragment, defaultValuesForQuizCard } from './quiz-form'
import { TextForm, TextFormFragment, defaultValuesForTextCard } from './text-form'
import { VideoForm, VideoFormFragment, defaultValuesForVideoCard } from './video-form'
import { Card, ImageCard, OpenCard, QuizCard, TextCard, VideoCard } from '../../types'
import {useTranslation} from "react-i18next";

export interface CardValues {
  cardType: CardType;
  title: string;
}

const CARD_TYPES: { [key: string]: React.ComponentType<BaseFormProps<CardValues>> } = {
  TEXT: TextForm,
  IMAGE: ImageForm,
  VIDEO: VideoForm,
  QUIZ: QuizForm,
  OPEN: OpenForm,
}

const CARD_FORM_QUERY = gql`
  query CardFormQuery($cardId: ID!) {
    card(id: $cardId) {
      id
      title
      cardType
      botModuleId
      __typename

      ...ImageFormFragment
      ...OpenFormFragment
      ...QuizFormFragment
      ...TextFormFragment
      ...VideoFormFragment
    }
  }

  ${ImageFormFragment}
  ${OpenFormFragment}
  ${QuizFormFragment}
  ${TextFormFragment}
  ${VideoFormFragment}
`

interface CardFormQueryData {
  card: Card;
}

interface CardFormQueryVars {
  cardId: string | null;
}

type UseCardValuesReturn = [(card: { cardType: CardType }) => CardValues, { card: Card | null, loading: boolean, error: ApolloError | undefined }]

function useCardValues(card: { id: string | null }): UseCardValuesReturn {
  let { data, loading, error } = useQuery<CardFormQueryData, CardFormQueryVars>(CARD_FORM_QUERY, {
    variables: { cardId: card.id },
    skip: !card.id,
  })

  let defaultValuesForCard = React.useCallback((card: { cardType: CardType }) => {
    switch (card.cardType) {
      case CardType.IMAGE:
        return defaultValuesForImageCard(data?.card as ImageCard || null)
      case CardType.OPEN:
        return defaultValuesForOpenCard(data?.card as OpenCard || null)
      case CardType.QUIZ:
        return defaultValuesForQuizCard(data?.card as QuizCard || null)
      case CardType.TEXT:
        return defaultValuesForTextCard(data?.card as TextCard || null)
      case CardType.VIDEO:
        return defaultValuesForVideoCard(data?.card as VideoCard || null)
      default:
        throw new Error(`Unable to resolve default values for unknown card type "${card.cardType}"`)
    }
  }, [data])

  return [defaultValuesForCard, { card: data ? data.card : null, loading, error }]
}

export interface CardFormProps extends Omit<BaseFormProps<CardValues>, 'card' | 'defaultValues'> {
  card: { id: null, botModuleId: string, cardType: CardType } | { id: string, botModuleId: string, cardType: CardType, title: string }
  className?: string
  style?: React.CSSProperties
  companyId: string
}

export const CardForm: React.VFC<CardFormProps> = props => {
  let { card: unusedCard, className, style, ...rest } = props
  const { t, i18n } = useTranslation();

  let ComponentForCardType = CARD_TYPES[props.card.cardType]

  if (!ComponentForCardType) {
    throw new Error(`Unable to render form for unknown card type "${props.card.cardType}"`)
  }

  let [defaultValuesForCard, { card, loading, error }] = useCardValues(props.card)

  if (loading) {
    return <p>{t('loading')}.</p>
  }

  if (error || (props.card.id && !card)) {
    return <p>{t('error')}</p>
  }

  return (
    <div className={cx('shadow rounded-md overflow-hidden', className)} style={style}>
      <div className="bg-white space-y-6 p-6">
        <ComponentForCardType
          card={card ?? props.card}
          defaultValues={defaultValuesForCard(card ?? props.card)}
          {...rest}
        />
      </div>
    </div>
  )
}
