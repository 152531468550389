import { gql, useMutation } from '@apollo/client'
import { BlobUpload } from '@rails/activestorage/src/blob_upload'
import { FileChecksum } from '@rails/activestorage/src/file_checksum'

const DIRECT_UPLOAD_MUTATION = gql`
  mutation CreateDirectUploadMutation($input: CreateDirectUploadMutationInput!) {
    createDirectUpload(input: $input) {
      directUpload {
        url
        headers
        signedBlobId
      }
    }
  }
`

interface CreateDirectUploadMutationInput {
  checksum: string;
  filename: string;
  contentType: string;
  byteSize: number
}

export interface DirectUpload {
  url: string;
  headers: string
  signedBlobId: string;
}

export type DirectUploadMutationFn = (file: File) => Promise<string>

export default function useDirectUploadMutation(): DirectUploadMutationFn {
  const [mutate] = useMutation<{ createDirectUpload: { directUpload: DirectUpload } }, { input: CreateDirectUploadMutationInput }>(DIRECT_UPLOAD_MUTATION)

  let uploadFn = (file: File) =>
    calculateChecksum(file).then(checksum =>
      mutate({
        variables: {
          input: { checksum, filename: file.name, contentType: file.type, byteSize: file.size }
        }
      })
    ).then(({ data }) => {
      let { url, headers, signedBlobId } = data!.createDirectUpload.directUpload;
      return directUpload(url, JSON.parse(headers), file).then(() => signedBlobId);
    })

  return uploadFn
}

function calculateChecksum(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(checksum);
    });
  });
}

function directUpload(url: string, headers: Record<string, string>, file: File): Promise<void> {
  const upload = new BlobUpload({ file, directUploadData: { url, headers } });

  return new Promise((resolve, reject) => {
    upload.create(error => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    })
  });
}
