import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import {SlideOver} from "../components/SlideOver";
import {UserForm, UserValues} from "../components/UserForm";
import {useTranslation} from "react-i18next";

interface UserCreateRouteProps {
	companyId: string;
}

const UsersCreateRoute: React.FC<UserCreateRouteProps> = ({ companyId }) => {
	const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery(gql`
    query UsersCreateRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        departments {
          id
          name
        }
      }
    }
  `, {
    variables: {
      companyId
    }
  });

  const [createUser] = useMutation(gql`
    mutation UsersCreateRoute_CreateUserMutation($input: CreatUserMutationInput!) {
      createUser(input: $input) {
        user {
          id
        }
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'UsersOverviewRouteQuery',
    ]
  })
  function onCancel() {
			navigate(`/${companyId}/users`)
  }
  async function onSubmit(values: UserValues) {
		let { data } = await createUser({ variables: { input: { companyId, ...values } } })
		navigate({pathname: `/${companyId}/users`}, {replace: true})
  }

	return (
		<SlideOver title={t('create_user')} onClose={onCancel}>
			<UserForm
				onCancel={onCancel}
				onSubmit={onSubmit}
			/>
		</SlideOver>
	)
}

export default UsersCreateRoute;
