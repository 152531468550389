import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { SlideOver } from '../components/SlideOver';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import DepartmentManageBotUsersForm, {DepartmentManageBotUsersValues} from "../components/DepartmentManageBotUsersForm";
import {
  DepartmentsManageBotUsersRoute_ManageBotUsersDepartmentMutation,
  DepartmentsManageBotUsersRoute_ManageBotUsersDepartmentMutationVariables
} from "../__generated__/DepartmentsManageBotUsersRoute_ManageBotUsersDepartmentMutation";

interface DepartmentsManageBotUsersRouteProps {
  companyId: string;
}

const DepartmentsManageBotUsersRoute: React.FC<DepartmentsManageBotUsersRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { departmentId } = useParams<'departmentId'>();
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)

  const { t, i18n } = useTranslation();

  const [manageBotUsersForDepartment] = useMutation<DepartmentsManageBotUsersRoute_ManageBotUsersDepartmentMutation, DepartmentsManageBotUsersRoute_ManageBotUsersDepartmentMutationVariables>(gql`
    mutation DepartmentsManageBotUsersRoute_ManageBotUsersDepartmentMutation($input: ManageBotUsersDepartmentMutationInput!) {
      manageBotUsersDepartment(input: $input) {
        department {
          id
        } 
        errors
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/departments`)
  }

  async function onSubmit(values: DepartmentManageBotUsersValues) {
    let { data } = await manageBotUsersForDepartment({ variables: { input: { id: departmentId as string, ...values } } })

    if (data?.manageBotUsersDepartment?.errors.length == 0) {
      navigate(`/${companyId}/departments`)
    } else {
      setMutationErrors(data?.manageBotUsersDepartment?.errors!)
    }
  }

  return (
    <SlideOver title={t('manage_bot_users')} onClose={onCancel}>
      <DepartmentManageBotUsersForm
        companyId={companyId}
        onCancel={onCancel}
        onSubmit={onSubmit}
        departmentId={departmentId}
      />
      {mutationErrors && mutationErrors.length > 0 &&
          <div className="bottom-10 px-5 py-4 border-r-8 bg-red-500 bg-white drop-shadow-lg">
            <p className="text-sm text-white">
              <span className="mr-2 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
              {mutationErrors.join(', ')}
            </p>
          </div>
      }
    </SlideOver>
  )
}

export default DepartmentsManageBotUsersRoute;
