import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation, useQuery } from '@apollo/client';
// import { BotUserDetailsForm_botUser } from '../__generated__/BotUserDetailsForm_botUser';
import {BotUserForm_botUser} from "../__generated__/BotUserForm_botUser";
import { Button } from './Button';
import { TextInputField } from './text-input-field';
import { SelectField } from './select-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from "react-i18next";

const UserSchema = yup.object().shape({
  name: yup.string().trim().required().label("name"),
  username: yup.string().trim().required().label("Username"),
  email: yup.string().trim().email().label("Email"),
  role: yup.string().oneOf(['admin', 'content_creator', 'supervisor']).nullable().label("Role"),
})

export interface UserValues {
  name: string
  username: string
  email: string | null
  password: string | null
  role: string | null
}

export interface UserFormProps {
  user?: {
    name: string
    username: string
    email: string | null
    password: string | null
    role: string | null
  }
  className?: string
  onCancel?: () => void;
  onSubmit: SubmitHandler<UserValues>;
}


export const UserForm: React.FunctionComponent<UserFormProps> = ({ user, className, onCancel, onSubmit }) => {
  const { t, i18n } = useTranslation();
  let [passwordStrength, setPasswordStrength] = React.useState<number>(0)

  const defaultValues: DefaultValues<UserValues> = {
    name: user?.name ?? '',
    username: user?.username ?? '',
    email: user?.email ?? '',
    password: user?.password ?? '',
    role: user?.role ?? 'admin'
  }

  const { register, handleSubmit, formState } = useForm<UserValues>({ defaultValues, resolver: yupResolver(UserSchema) })

  function handlePasswordChanged(event: any){
    const pass = event.currentTarget.value;
    
    let count = 0; 
    if (pass.length > 8){
      count ++;
    }
    let regex1 = /[a-z]/; 
    if (regex1.test(pass)) count++; 
    let regex2 = /[A-Z]/; 
    if (regex2.test(pass)) count++; 
    let regex3 = /[\d]/; 
    if (regex3.test(pass)) count++; 
    let regex4 = /[!@#$%^&*.?]/; 
    if (regex4.test(pass)) count++;

    setPasswordStrength(Math.min(100, 100 / 4 * count))
  }

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-3">
              <TextInputField label={t('first_name')} {...register('name')} errors={formState.errors} />
            </div>
            <div className="col-span-3">
              <TextInputField label={t('username')} {...register('username')} errors={formState.errors}/>
            </div>
            <div className="col-span-3">
              <TextInputField label={t('email')} type="email" { ...register('email') } errors={formState.errors}/>
            </div>
            <div className="col-span-3">
              <TextInputField label={t('password')} type="text" { ...register('password') } onChange={(event)=> handlePasswordChanged(event) }errors={formState.errors}/>
              { passwordStrength > 0 && (
                <div style={{width: '100%', height: '10px', marginTop: '5px', background: '#e7e7e7'}}>
                  <div style={{width: passwordStrength + '%', height: '10px', backgroundColor: passwordStrength >= 75 ? 'green' : passwordStrength >= 50 ? 'orange' : 'red'}}></div>
                </div>
              ) }
            </div>
            <div className="col-span-3">
              <SelectField label={t('role')} {...register('role')} errors={formState.errors} >
                <option key={'admin'} value="admin">{t('user_role_admin')}</option>
                <option key={'content_creator'} value="content_creator">{t('user_role_content_creator')}</option>
                <option key={'supervisor'} value="supervisor">{t('user_role_supervisor')}</option>
              </SelectField>
            </div>
          </div>
        </div>

        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}
