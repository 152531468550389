import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from "@apollo/client/utilities";

export function createClient (csrfToken: string) {
  return new ApolloClient({
    uri: '/graphql',
    headers: {
      'X-CSRF-TOKEN': csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    },
    cache: new InMemoryCache({
      possibleTypes: {
        Card: ['ImageCard', 'OpenCard', 'TextCard', 'QuizCard', 'VideoCard'],
      },
      typePolicies: {
        Query: {
          fields: {
            botUsers: relayStylePagination(),
          },
        },
      },
    })
  });
}
