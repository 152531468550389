import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation, useQuery } from '@apollo/client';
// import { BotUserDetailsForm_botUser } from '../__generated__/BotUserDetailsForm_botUser';
import {BotUserForm_botUser} from "../__generated__/BotUserForm_botUser";
import { Button } from './Button';
import { TextInputField } from './text-input-field';
import { SelectField } from './select-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from "react-i18next";

const UserAttachSchema = yup.object().shape({
  userId: yup.string().required().label("Users"),
})

export interface UserAttachValues {
  userId: string
}

export interface UserAttachFormProps {
  users: {id: string, username: string, email: string}[]
  className?: string
  onCancel?: () => void;
  onSubmit: SubmitHandler<UserAttachValues>;
}


export const UserAttachForm: React.FunctionComponent<UserAttachFormProps> = ({ users, className, onCancel, onSubmit }) => {
  const { t, i18n } = useTranslation();

  const defaultValues: DefaultValues<UserAttachValues> = {
    userId: ''
  }

  const { register, handleSubmit, formState } = useForm<UserAttachValues>({ defaultValues, resolver: yupResolver(UserAttachSchema) })

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <SelectField label={t('users')} {...register('userId')} errors={formState.errors}>
            {users.map(user => (
              <option key={user.id} value={user.id}>
                {user.username} - {user.email}
              </option>
            ))}
          </SelectField>
        </div>

        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}
