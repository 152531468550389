import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {gql, useMutation, useQuery} from '@apollo/client';
import { DepartmentsCreateRoute_CreateDepartmentMutation, DepartmentsCreateRoute_CreateDepartmentMutationVariables } from '../__generated__/DepartmentsCreateRoute_CreateDepartmentMutation';
import { DepartmentForm, DepartmentValues } from '../components/DepartmentForm';
import { SlideOver } from '../components/SlideOver';
import {DepartmentCreateRouteQuery} from "../__generated__/DepartmentCreateRouteQuery";
import {useTranslation} from "react-i18next";

interface DepartmentsCreateRouteProps {
  companyId: string;
  userRole: string | null
}

const DepartmentsCreateRoute: React.FC<DepartmentsCreateRouteProps> = ({ userRole, companyId }) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const [createDepartment] = useMutation<DepartmentsCreateRoute_CreateDepartmentMutation, DepartmentsCreateRoute_CreateDepartmentMutationVariables>(gql`
    mutation DepartmentsCreateRoute_CreateDepartmentMutation($input: CreateDepartmentMutationInput!) {
      createDepartment(input: $input) {
        department {
          id
        }
      }
    }
  `)

  const { data, loading, error } = useQuery<DepartmentCreateRouteQuery>(gql`
    query DepartmentCreateRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        whatsappBroadcastTemplates {
          id
          name
          displayName
          language
          body
          header
        }
      }
    }
  `, {variables: {companyId: companyId as String}});

  if (error) {
    return <p>{t('error')}</p>
  }

  function onCancel() {
    navigate(`/${companyId}/departments`)
  }

  async function onSubmit(values: DepartmentValues) {
    await createDepartment({ variables: { input: { companyId, ...values } } })
    navigate(`/${companyId}/departments`)
  }

  if (!data || !data.company){
    return null;
  }
  return (
    <SlideOver title={t('create_department')} onClose={onCancel}>
      <DepartmentForm
        userRole={userRole}
        whatsappBroadcastTemplates={data!.company!.whatsappBroadcastTemplates}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </SlideOver>
  )
}

export default DepartmentsCreateRoute;
