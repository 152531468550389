import * as React from 'react'
import { useId } from '@reach/auto-id'
import {ErrorMessage} from "@hookform/error-message";
import {WhatsappBroadcastFormQuery_company_whatsappBotUsers} from "../../__generated__/WhatsappBroadcastFormQuery";
import {SubscriptionFormQuery_companies} from "../../__generated__/SubscriptionFormQuery";

export interface CompanyCheckboxList {
  name: string
  setValue: any
  getValues: any
  id?: string
  className?: string
  label?: string
  errors?: any
  children: any
  style?: any
  companies?: SubscriptionFormQuery_companies[],
}

export const CompaniesCheckboxList = React.forwardRef<HTMLInputElement, CompanyCheckboxList>((props, ref) => {

  const { id: unusedId, setValue, getValues, className, style, label, name, errors, companies, onChange } = props

  let selectedCompanyIds = Array.from(getValues("companyIds") || [])

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newId = event.currentTarget.value;

    if(!selectedCompanyIds.includes(newId)){
      selectedCompanyIds.push(newId);
    }else{
      selectedCompanyIds.splice(selectedCompanyIds.indexOf(newId), 1);  //deleting
    }

    setValue("companyIds", selectedCompanyIds.length > 0 ? selectedCompanyIds : ['']);
    onChange(event)
  };

  return (
    <div ref={ref} className="" style={style}>
      <label className=" block text-sm font-medium text-gray-700" htmlFor={unusedId}>
        {label}
      </label>
      <div ref={ref} className="block w-full pl-3 pr-4 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border" style={style}>
        <div>
          {(companies || []).map(edge => (
            <div key={edge!.id}>
              <label className="flex w-full">
                <span className="flex-1">{edge!.name}</span>
                <input type="checkbox" checked={selectedCompanyIds.includes(edge!.id)} onChange={onChangeSelect} key={edge!.id} value={edge!.id}>
                </input>
              </label>
            </div>
          ))}
        </div>
      </div>
      <ErrorMessage errors={errors} className="mt-2 text-sm text-red-600" name={name} as="p" />
    </div>
  )
})
