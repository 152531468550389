import * as React from 'react'
import { useId } from '@reach/auto-id'
import cx from 'clsx'
import { FormField, FormFieldProps } from './FormField'

export interface NumberInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(({ className, ...props }, ref) => {
  return (
    <input ref={ref} type="number" className={cx('shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:number-sm border-gray-300 rounded-md', className)} {...props} />
  )
})

export interface NumberInputFieldProps extends Omit<FormFieldProps, 'labelFor'>, NumberInputProps {
  name: string
}

export const NumberInputField = React.forwardRef<HTMLInputElement, NumberInputFieldProps>((props, ref) => {
  const id = useId(props.id);
  const { id: unusedId, className, label, ...rest } = props

  return (
    <FormField className={className} label={label} labelFor={id} name={props.name} errors={props.errors}>
      <NumberInput ref={ref} id={id} {...rest} />
    </FormField>
  )
})
