import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation, useQuery } from '@apollo/client';
// import { BotUserDetailsForm_botUser } from '../__generated__/BotUserDetailsForm_botUser';
import {BotUserForm_botUser} from "../__generated__/BotUserForm_botUser";
import {Button, Intent} from './Button';
import { TextInputField } from './text-input-field';
import { SelectField } from './select-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {makeDeleteBotUserMutation} from "../queries/BotUsersQueryHandler";
import { useNavigate, useParams } from 'react-router-dom';
import { MobileInputField } from './mobile-input-field';

const BotUserSchema = yup.object().shape({
  firstName: yup.string().trim().required().label("First name"),
  lastName: yup.string().trim().required().label("Last name"),
  email: yup.string().trim().email().label("Email").when('mobile', {
    is: (mobile: string) => !mobile,
    then: yup.string().email().required(),
    otherwise: yup.string().email()
  }),
  mobile: yup.string().label("Mobile").when('email', {
    is: (email: string) => !email,
    then: yup.string().trim().required().label("Mobile"),
    // then: yup.string().trim().matches(/^\+[0-9]{11}$/, { message: "Mobile is required and must start with + and rest digits" }).required(),
    // otherwise: yup.string().trim().matches(/^\+[0-9]{11}$/, { excludeEmptyString: true, message: "Mobile must start + and rest digits" })
  })
}, [['email', 'mobile']])

export interface BotUserValues {
  firstName: string
  lastName: string
  testUser: boolean
  email: string | null
  mobile: string | null
  departmentId: string
  botUserType: string
}

export interface BotUserFormProps {
  companyId: string
  departments?: DepartmentValues[]
  departmentId?: string | null
  botUser?: BotUserForm_botUser
  className?: string
  useSteps?: boolean | true
  onCancel?: () => void;
  onSubmit: SubmitHandler<BotUserValues>;
}

interface DepartmentValues {
  id: string
  name: string
  kind: String
}

const BOT_USER_TYPES = ["whatsapp", "web"];

export const BotUserForm: VFCwF<BotUserFormProps> = ({ companyId, botUser, departments, departmentId, className, useSteps, onCancel, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  let startStep = useSteps ? 1 : 4;
  let initialShowWarnings = false;

  if (departmentId && startStep == 1){
    startStep = 2;
    const departmentKind = departments?.find(x => x.id == departmentId)?.kind;
    initialShowWarnings = departmentKind == 'scheduled' || departmentKind == 'available'
  }

  console.log('-----------------')
  console.log(initialShowWarnings)

  const [warnings, showWarnings] = React.useState(initialShowWarnings)
  const [step, setStep] = React.useState(startStep)
  const [selectedType, setSeletedType] = React.useState(botUser?.botUserType)

  const defaultValues: DefaultValues<BotUserValues> = {
    firstName: botUser?.firstName ?? '',
    lastName: botUser?.lastName ?? '',
    email: botUser?.email ?? '',
    mobile: botUser?.mobile ?? '',
    testUser: botUser?.testUser ?? false,
    botUserType: botUser?.botUserType ?? 'whatsapp',
    departmentId: departmentId || botUser?.departmentId || null
  }

  const [deleteBotUser] = makeDeleteBotUserMutation()
  function onBotUserDelete(id: string) {
    if(window.confirm(t('are_you_sure'))) {
      deleteBotUser({variables: {input: {id}}})
          .then(({data}) => navigate(`/${companyId}/bot_users`))
    }
  }

  const onDepartmentChanged = (event: any) =>{
    const department = departments?.find(x => x.id == event.currentTarget.value);
    if (department?.kind == 'scheduled' || department?.kind == 'available' ){
      showWarnings(true)
    } else {
      showWarnings(false);
    }
    setStep(useSteps ? 2 : 4)
  }
  const { register, handleSubmit, formState, setValue , getValues} = useForm<BotUserValues>({ defaultValues, resolver: yupResolver(BotUserSchema) })

  const onTypeChanged = (event: any) => {
    setValue('botUserType', event)
    setSeletedType(event);
    setStep(useSteps ? 3 : 4)
  }

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="grid grid-cols-6 gap-6">
            {step >= 1 && departments &&
              <>
                <div className={useSteps ? "col-span-6" : "col-span-3"}>
                  <SelectField label={t('department')} {...register('departmentId')} onChange={(evt) => onDepartmentChanged(evt)} errors={formState.errors}>
                    <option value="">{i18n.t('search_by_name_or_tag')}</option>
                    {departments.map(department => (
                      <option key={department.id}  value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </SelectField>
                </div>
                {warnings &&
                  <div className="rounded-md col-span-6 px-5 py-4 bg-orange-500 bg-white">
                    <div className="flex items-start text-sm text-white">
                      <span className="mr-4 inline-block px-3 py-1 rounded-full bg-orange-500 text-white drop-shadow-lg font-extrabold">i</span>
                      {t('automated_department_warning')}
                    </div>
                  </div>
                }
              </>
            }
            {step >= 2 &&
              <div className={useSteps ? "col-span-6" : "col-span-3"}>
                <label className="mb-1 block text-sm font-medium text-gray-700">{t('type')}</label>
                {BOT_USER_TYPES.map(type => (
                  <div key={type} >
                    {type == 'whatsapp' ? (
                      <div className={selectedType == 'whatsapp' ? "bg-green-600 text-white rounded-md mb-2 grid gap-4 grid-cols-2 border border-gray-300 p-2 px-4" : "rounded-md mb-2 grid gap-4 grid-cols-2 border border-gray-300 p-2 px-4"} onClick={e => onTypeChanged('whatsapp')}>
                        <span className="material-symbols-outlined">
                          chat
                        </span>
                        <span className='ml-2 text-right'>Whatsapp</span>
                      </div>
                    ) : (
                      <div className={selectedType == 'web' ? "bg-blue-600 text-white rounded-md mb-0 grid gap-4 grid-cols-2 border border-gray-300 p-2 px-4" : "rounded-md mb-0 grid gap-4 grid-cols-2 border border-gray-300 p-2 px-4"} onClick={e => onTypeChanged('web')}>
                        <span className="material-symbols-outlined">
                          mail
                        </span>
                        <span className='ml-2 text-right'>E-mail</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            }

            {step >= 3 &&
              <>
                <div className={useSteps ? "col-span-6" : "col-span-3"}>
                  <div>
                    <TextInputField label={t('first_name')} {...register('firstName')} errors={formState.errors} />
                  </div>
                  <div className="mt-2">
                    <TextInputField label={t('last_name')} {...register('lastName')} errors={formState.errors}/>
                  </div>
                </div>
                {selectedType == 'web' &&
                  <div className={useSteps ? "col-span-6" : "col-span-3"}>
                    <TextInputField label={t('email')} type="email" placeholder={t('email_placeholder')} { ...register('email') } errors={formState.errors}/>
                  </div>
                }
                {selectedType == 'whatsapp' &&
                  <div className={useSteps ? "col-span-6" : "col-span-3"}>
                    <MobileInputField label={t('mobile')} type="tel" placeholder={t('mobile_placeholder')} { ...register('mobile') } errors={formState.errors}/>
                  </div>
                }
              </>

            }
          </div>
        </div>
        {step >= 3 &&
          <div className="px-6 py-3 bg-gray-50 text-right flex justify-end align-items-center">
            <Button type="submit" disabled={formState.isSubmitting}>
              {t('save')}
            </Button>
            {botUser != null && botUser.id != null &&
              <>
                <div className="ml-2"></div>
                <Button intent={Intent.Danger} type="submit" onClick={() => onBotUserDelete(botUser.id)} disabled={formState.isSubmitting}>
                  {t('delete')}
                </Button>
              </>
            }
          </div>
        }
      </div>
    </form>
  )
}

BotUserForm.fragments = {
  botUser: gql`
    fragment BotUserForm_botUser on BotUser {
      id
      firstName
      lastName
      email
      mobile
      departmentId
      botUserType
      company {
        id
        departments {
          id
          name
          kind
        }
      }
    }
  `
}
