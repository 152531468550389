import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { FolderForm, FolderValues } from '../components/FolderForm';
import { SlideOver } from '../components/SlideOver';
import { FoldersCreateRoute_CreateFolderMutation, FoldersCreateRoute_CreateFolderMutationVariables } from '../__generated__/FoldersCreateRoute_CreateFolderMutation';
import {useTranslation} from "react-i18next";

interface FoldersCreateRouteProps {
  companyId: string;
}

const FoldersCreateRoute: React.VFC<FoldersCreateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const [createFolder] = useMutation<FoldersCreateRoute_CreateFolderMutation, FoldersCreateRoute_CreateFolderMutationVariables>(gql`
    mutation FoldersCreateRoute_CreateFolderMutation($input: CreateFolderMutationInput!) {
      createFolder(input: $input) {
        folder {
          id
        }
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/bot_modules`)
  }

  async function onSubmit(values: FolderValues) {
    let { data } = await createFolder({ variables: { input: { companyId, ...values } } })
    navigate(`/${companyId}/bot_modules?folder=${data!.createFolder!.folder.id}`)
  }

  return (
    <SlideOver title={t('create_folder')} onClose={onCancel}>
      <FolderForm
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </SlideOver>
  )
}

export default FoldersCreateRoute;
