import * as React from 'react'
import { useId } from '@reach/auto-id'
import cx from 'clsx'
import { FormField, FormFieldProps } from '../FormField'

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(({ className, disabled, type = 'text', ...props }, ref) => (
    <input
      ref={ref}
      type={type}
      disabled={disabled}
      className={cx(
        'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md',
        { 'bg-gray-100 text-gray-400': disabled === true },
        className
      )}
      {...props}
    />
  )
)

export interface TextInputFieldProps extends Omit<FormFieldProps, 'labelFor'>, TextInputProps {
  name: string
}

export const TextInputField = React.forwardRef<HTMLInputElement, TextInputFieldProps>((props, ref) => {
  const id = useId(props.id);
  const { id: unusedId, className, label, ...rest } = props

  return (
    <FormField className={className} label={label} labelFor={id} name={props.name} errors={props.errors}>
      <TextInput ref={ref} id={id} {...rest}/>
    </FormField>
  )
})
