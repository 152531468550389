import React from 'react';
import { usePopper } from 'react-popper';

interface PopoverProps {
  input?: string | string[]
  windowClass?: string
  children?: any
}

// A popover component which shows a window when hovering over the reference element. Currently its an empty div
// but child components can be put insice like for ex an icon.
// With windowClass you can add extraa class values to the popover window like for ex text color.
// Input has to be an array of text lines
// Use ex:
//
// <Popover input={importArray} windowClass="text-red-500" >
//   <ExclamationCircleIcon className="w-8 h-8 text-red-500 hover:text-red-200 hover:cursor-pointer" />
// </Popover>
//
const Popover: React.VFC<PopoverProps> = ({ input, windowClass = "text-black", children }) => {
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [element, setElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, element, {
    placement: 'bottom',
    modifiers: [{ name: 'offset', options: { offset: [0, 2] }}]
  });

  const [hoverVis, setHoverVis] = React.useState(false);
  const [toggleVis, setToggleVis] = React.useState(false);

  return (
    !!input?.length && (
      <div ref={setReferenceElement}
           onClick={() => setToggleVis(!toggleVis)}
           onMouseEnter={() => {setHoverVis(true); setToggleVis(false)}}
           onMouseLeave={() => setHoverVis(false)}
      >
        {children}
        {(toggleVis || hoverVis) &&
          <div ref={setElement} className={`margin-0 bg-white p-4 ${windowClass} border border-sm rounded`} style={styles.popper} {...attributes.popper}>
            {Array.isArray(input) && input?.map((res:string, idx: number) => <p className="whitespace-pre" key={idx}>{res}</p>)}
            {typeof input === "string" && <p className="whitespace-pre">{input}</p>}
          </div>
        }
      </div>
    )
  )
}

export default Popover;
