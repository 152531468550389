import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql } from '@apollo/client';
import { FolderForm_folder } from '../__generated__/FolderForm_folder';
import { Button, Appearance } from './Button';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

const FolderSchema = yup.object({
  name: yup.string().required().label("Name")
})

export interface FolderValues {
  name: string;
}

export interface FolderFormProps {
  folder?: FolderForm_folder
  onCancel: () => void;
  onSubmit: SubmitHandler<FolderValues>;
}

export const FolderForm: VFCwF<FolderFormProps> = ({ folder, onCancel, onSubmit }) => {
  const defaultValues: DefaultValues<FolderValues> = { name: folder?.name };
  const { register, handleSubmit, formState } = useForm<FolderValues>({ defaultValues, resolver: yupResolver(FolderSchema) })
  const { t, i18n } = useTranslation();

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <TextInputField label={t('name')} {...register('name')} errors={formState.errors} />
      </div>

      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

FolderForm.fragments = {
  folder: gql`
    fragment FolderForm_folder on Folder {
      name
    }
  `
}

export default FolderForm
