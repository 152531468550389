import {gql, useMutation} from "@apollo/client";
import {
  CompanyChannelsForm_UpdateCompanyMutation,
  CompanyChannelsForm_UpdateCompanyMutationVariables
} from "../__generated__/CompanyChannelsForm_UpdateCompanyMutation";

export const makeCompanyMutation = () => {
  const result = useMutation<CompanyChannelsForm_UpdateCompanyMutation, CompanyChannelsForm_UpdateCompanyMutationVariables>(
    gql`
      mutation CompanyChannelsForm_UpdateCompanyMutation($input: UpdateCompanyMutationInput!) {
        updateCompany(input: $input) {
          company {
            id
          }
          errors
        }
      }
    `
  )

  return result;
}
