import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { SlideOver } from '../components/SlideOver';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import DepartmentManageShopCompaniesForm, {  DepartmentManageShopCompaniesValues } from '../components/DepartmentManageShopCompaniesForm';
import { DepartmentsManageShopRoute_ManageShopCompanyMutation, DepartmentsManageShopRoute_ManageShopCompanyMutationVariables } from '../__generated__/DepartmentsManageShopRoute_ManageShopCompanyMutation';

interface DepartmentsManageShopCompaniesRouteProps {
  companyId: string;
}

const DepartmentsManageShopCompaniesRoute: React.FC<DepartmentsManageShopCompaniesRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { departmentId } = useParams<'departmentId'>();
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)

  const { t, i18n } = useTranslation();

  const [manageBotUsersForDepartment] = useMutation<DepartmentsManageShopRoute_ManageShopCompanyMutation, DepartmentsManageShopRoute_ManageShopCompanyMutationVariables>(gql`
    mutation DepartmentsManageShopRoute_ManageShopCompanyMutation($input: ManageShopCompaniesDepartmentMutationInput!) {
      manageShopCompaniesDepartment(input: $input) {
        department {
          id
        } 
        errors
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/departments`)
  }

  async function onSubmit(values: DepartmentManageShopCompaniesValues) {
    let { data } = await manageBotUsersForDepartment({ variables: { input: { id: departmentId as string, ...values } } })

    if (data?.manageShopCompaniesDepartment?.errors.length == 0) {
      navigate(`/${companyId}/departments`)
    } else {
      setMutationErrors(data?.manageShopCompaniesDepartment?.errors!)
    }
  }

  return (
    <SlideOver title={t('manage_shop_departments')} onClose={onCancel}>
      <p>{t('manage_shop_departments_explanation')}</p>
      <DepartmentManageShopCompaniesForm
        companyId={companyId}
        onCancel={onCancel}
        onSubmit={onSubmit}
        departmentId={departmentId}
      />
      {mutationErrors && mutationErrors.length > 0 &&
          <div className="bottom-10 px-5 py-4 border-r-8 bg-red-500 bg-white drop-shadow-lg">
            <p className="text-sm text-white">
              <span className="mr-2 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
              {mutationErrors.join(', ')}
            </p>
          </div>
      }
    </SlideOver>
  )
}

export default DepartmentsManageShopCompaniesRoute;
