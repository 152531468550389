import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { Button } from './Button';
import { TextInputField } from './text-input-field';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useTranslation} from "react-i18next";

const UserSchema = yup.object().shape({
  companyName: yup.string().trim().required().label("name"),
  mobile: yup.string().trim().label("mobile"),
  apiKey: yup.string().trim().label("apiKey"),
  accountId: yup.string().trim().label("accountId")
})

export interface CompanyValues {
  companyName: string
  mobile: string
  email: string
  apiKey: string
  code: string
  accountId: string
}

export interface CompanyFormProps {
  company?: {
    companyName: string
    mobile: string
    email: string
    apiKey: string
    code: string
    accountId: string
  }
  className?: string
  onCancel?: () => void;
  onSubmit: SubmitHandler<CompanyValues>;
}


export const CompanyForm: React.FunctionComponent<CompanyFormProps> = ({ company, className, onCancel, onSubmit }) => {
  const defaultValues: DefaultValues<CompanyValues> = {
    companyName: company?.companyName ?? '',
    mobile: company?.mobile ?? '',
    email: company?.email ?? '',
    apiKey: company?.apiKey ?? 'bf6eb335-4540-423e-9891-284a053bed71',
    code: company?.code ?? '',
    accountId: company?.accountId ?? '8d836dd3-525b-49dc-91e2-38c53693c86d',
  }

  const { t, i18n } = useTranslation();
  const { register, handleSubmit, formState } = useForm<CompanyValues>({ defaultValues, resolver: yupResolver(UserSchema) })

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-12">
              <TextInputField label={t('company_name')} {...register('companyName')} errors={formState.errors} />
            </div>
            <div className="col-span-12">
              <TextInputField label={t('mobile')} {...register('mobile')} errors={formState.errors}/>
            </div>
            <div className="col-span-12">
              <TextInputField label={t('email')} type="email" { ...register('email') } errors={formState.errors}/>
            </div>
            <div className="col-span-12">
              <TextInputField label={t('api_key')} type="text" { ...register('apiKey') } errors={formState.errors}/>
            </div>
            <div className="col-span-12">
              <TextInputField label={t('code')} type="text" { ...register('code') } errors={formState.errors}/>
            </div>
            <div className="col-span-12">
              <TextInputField label={t('account_id')} type="text" { ...register('accountId') } errors={formState.errors}/>
            </div>
          </div>
        </div>

        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}
