import * as React from 'react';
import { SubmitHandler, useForm} from 'react-hook-form'
import { gql, useLazyQuery } from '@apollo/client';
import { WhatsappBroadcastFormQuery } from '../__generated__/WhatsappBroadcastFormQuery';
import { Button, Appearance } from './Button';
import { yupResolver } from '@hookform/resolvers/yup';
import {BotUsersCheckboxList} from "./whatsapp-broadcast-fields/bot-user-input";
import {useTranslation} from "react-i18next";
import {DepartmentManageBotUsersFormQuery} from "../__generated__/DepartmentManageBotUsersFormQuery";

export interface DepartmentManageBotUsersValues {
  botUserIds: string[]
}

export const DepartmentManageBotUsersForm: ({companyId, onCancel, onSubmit}: {
  departmentId: string | undefined;
  companyId: string;
  onCancel: any;
  onSubmit: any
}) => JSX.Element = ({ companyId, departmentId, onCancel, onSubmit }) => {
  const { control, register, handleSubmit, formState, watch, setValue, getValues } = useForm<DepartmentManageBotUsersValues>({ defaultValues: { botUserIds: [] } })
  const { t, i18n } = useTranslation();

  // need to watch, otherwise views wont update
  const selectedBotUserIds = watch("botUserIds");

  const [getBotUsers, { data, loading, error }] = useLazyQuery<DepartmentManageBotUsersFormQuery>(gql`
    query DepartmentManageBotUsersFormQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        botUsers {
          edges {
            node {
              id
              firstName
              lastName
              botUserType
              departmentId
              anonymous
              departments {
                id
              }
            }
          }
        }
      }
    }
  `, {
    fetchPolicy: 'network-only',
  })

  React.useEffect(() => {
    getBotUsers({ variables: { companyId } }).then((data)=> {
      const botUsers = data?.data?.company?.botUsers?.edges?.map(x => x?.node);
      if (botUsers) {
        const selectedBotUsers = botUsers!.filter((x) => x?.departments.some(d => d.id == departmentId)).map((x) => x?.id);
        if (selectedBotUsers) {
          setValue('botUserIds', selectedBotUsers!)
        }
      }
    });
  }, [])

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <BotUsersCheckboxList label={t('bot_users')!} whatsappBotUsers={data?.company?.botUsers} getValues={getValues} setValue={setValue} {...register('botUserIds')} errors={formState.errors}>
        </BotUsersCheckboxList>
      </div>

      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('send')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default DepartmentManageBotUsersForm
