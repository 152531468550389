import * as React from 'react';
import { gql } from '@apollo/client'
import { SubmitHandler, UseFormRegister, useFieldArray, FieldErrors, useFormContext, useFormState } from 'react-hook-form';
import { Tabs, TabList, TabPanels, TabPanel } from "@reach/tabs"
import { PlusIcon, TrashIcon } from '@heroicons/react/outline'
import { CardType } from '../../__generated__/globalTypes'
import { Button, Appearance, Intent, Size } from '../Button';
import { CardFormTab } from '../CardFormTabs';
import { FileInputField } from '../FileInputField';
import { NumberInputField } from '../NumberInputField';
import { TextInput } from '../text-input-field';
import { TextareaField } from '../textarea-field';
import { SelectField } from '../select-field';
import useDirectUploadMutation from '../../hooks/direct-upload-mutation';
import { Question, QuizCard } from '../../types'
import { BaseForm, BaseFormProps } from './base-form';
import { CardValues } from '.'
import { ErrorMessage } from '@hookform/error-message';
import { ImageInputField, imageTypeTest, imageSizeTest } from '../ImageInputField';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

export const QuizFormSchema = {
  quizMessageThreshold: yup.number().integer().required().label("Minimum correct questions"),
  questions: yup.array().of(
    yup.object({
      question: yup.string().required().label("Question text"),
      image: yup.object({
        blobId: yup.mixed().test(imageTypeTest).test(imageSizeTest).nullable().label("Image"),
      }),
      answerCorrectFeedback: yup.string().required().label("Correct feedback"),
      typingCorrect: yup.number().integer().required().min(0).max(10000).label("Right typing"),
      answerWrongFeedback: yup.string().required().label("Wrong feedback"),
      typingWrong: yup.number().integer().required().min(0).max(10000).label("Wrong typing"),
      questionOptions: yup.array().of(
        yup.object({
          correct: yup.boolean(),
          text: yup.string().required().label("Option text")
        })
      ).required().min(1).max(10).label("Question options")
    })
  ).required().min(1).label("Questions")
}

export const QuizFormFragment = gql`
  fragment QuizFormFragment on QuizCard {
    quizMessageThreshold
    questions {
      id
      question
      imageUrl
      answerCorrectFeedback
      typingCorrect
      answerWrongFeedback
      typingWrong

      questionOptions {
        id
        text
        correct
      }
    }
  }
`

interface QuestionOptionValues {
  id: string;
  text: string;
  correct: boolean;
  _destroy: boolean;
}

interface QuestionValues {
  id: string;
  question: string;
  image: {
    blobId: FileList | null | undefined | string
    _destroy: boolean
  }
  answerCorrectFeedback: string;
  typingCorrect: number;
  answerWrongFeedback: string;
  typingWrong: number;
  questionOptions: QuestionOptionValues[];
  _destroy: boolean;
}

export interface CardQuizValues extends CardValues {
  cardType: CardType.QUIZ
  questions: QuestionValues[]
  quizMessageThreshold: number
}

export function defaultValuesForQuizCard(card: QuizCard | null): CardQuizValues {
  return {
    cardType: CardType.QUIZ,
    title: card?.title || '',
    quizMessageThreshold: card?.quizMessageThreshold >= 0 ? card?.quizMessageThreshold : 0,
    questions: (card?.questions || []).map(question => ({
      id: question.id,
      question: question.question,
      image: {
        blobId: question.imageUrl || null,
        _destroy: false
      },
      answerCorrectFeedback: question.answerCorrectFeedback,
      typingCorrect: question.typingCorrect,
      answerWrongFeedback: question.answerWrongFeedback,
      typingWrong: question.typingWrong,
      questionOptions: question.questionOptions.map(questionOption => ({
        id: questionOption.id,
        text: questionOption.text,
        correct: questionOption.correct,
        _destroy: false
      })),
      _destroy: false
    }))
  }
}

interface QuestionOptionForm {
  correct: boolean;
  index: number;
  questionIndex: number;
  register: UseFormRegister<CardQuizValues>;
  onCorrect: () => void;
  onRemove: () => void;
}

const QuestionOptionForm: React.FC<QuestionOptionForm> = ({ correct, index, questionIndex, register, onCorrect, onRemove }) => {
  const formState = useFormState<QuestionOptionValues>();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    register(`questions.${questionIndex}.questionOptions.${index}.correct`)
    register(`questions.${questionIndex}.questionOptions.${index}._destroy`)
  }, [register])

  return (
    <div className="flex items-center space-x-1">
      <div className="flex justify-center w-8">
        <input
          id={`questions.${questionIndex}.questionOptions.${index}.correct`}
          type="checkbox"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          name={`questions.${questionIndex}.questionOptions.${index}.correct`}
          checked={correct}
          onChange={() => onCorrect()}
        />
      </div>

      <div className="flex-1">
        <TextInput {...register(`questions.${questionIndex}.questionOptions.${index}.text`)} />
        <ErrorMessage errors={formState.errors} className="mt-2 text-sm text-red-600" name={`questions.${questionIndex}.questionOptions.${index}.text`} as="p" />
      </div>

      <div>
        <Button type="button" appearance={Appearance.Outline} intent={Intent.Danger} size={Size.S} onClick={() => onRemove()}>
          <span className="sr-only">{t('remove')}</span>
          <TrashIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

interface QuestionFormProps {
  index: number;
  question: Question | null;
  register: UseFormRegister<CardQuizValues>;
  onRemove: () => void;
}

const QuestionForm: React.FC<QuestionFormProps> = ({ index, question, register, onRemove }) => {
  const { setValue, getValues, formState } = useFormContext<CardQuizValues>();
  const { fields, append, remove } = useFieldArray<CardQuizValues, `questions.${number}.questionOptions`, 'key'>({ name: `questions.${index}.questionOptions`, keyName: 'key' });
  const { t, i18n } = useTranslation();

  function appendOptions() {
    append({ text: t('answer_1'), correct: true })
    append({ text: t('answer_2'), correct: false })
  }

  function markFieldAsCorrect(key: string) {
    const oldOptionValues = getValues(`questions.${index}.questionOptions`)

    const newValue = fields.map(({ key: fieldKey, ...field }, fieldIndex) => ({
      ...field,
      correct: fieldKey === key ? !field.correct : field.correct,
      text: oldOptionValues[fieldIndex].text
    }));
    setValue(`questions.${index}.questionOptions`, newValue)
  }

  function markFieldAsDestroyed(id: string) {
    setValue(`questions.${index}.questionOptions`, fields.map(({ key, ...field }) => ({
      ...field,
      _destroy: field.id === id ? true : field._destroy
    })))
  }

  React.useEffect(() => {
    if (!fields.length) {
      appendOptions()
    }
    register(`questions.${index}._destroy`)
  }, [register])

  return (
    <div>
        <div className="flex justify-end">
          <Button type="button" appearance={Appearance.Outline} intent={Intent.Danger} size={Size.XS} onClick={() => onRemove()}>
            <TrashIcon className="h-4 w-4" />
            <span className="ml-2">{t('remove')}</span>
          </Button>
        </div>
      <div className="space-y-6">
        <TextareaField maxLength={1000} showHint={true} label={t('question')} {...register(`questions.${index}.question`)} errors={formState.errors}/>
        <ImageInputField
          preview={question?.imageUrl}
          onRemove={() => setValue(`questions.${index}.image`, {blobId: null, _destroy: true}, { shouldValidate: true })}
          errors={formState.errors}
          {...register(`questions.${index}.image.blobId`)}
        />
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div className="text-sm font-medium text-gray-700">
              {t('options')}
            </div>

            <div className="flex flex-col items-end">
              <Button type="button" className="ml-2" appearance={Appearance.Outline} size={Size.S} onClick={() => append({ text: '', correct: fields.length === 0 })}>
                <span className="sr-only">{t('add')}</span>
                <PlusIcon className="h-4 w-4" />
              </Button>

              <ErrorMessage className="mt-2 text-sm text-red-600" name={`questions.${index}.questionOptions`} as="p" errors={formState.errors}/>
            </div>
          </div>

          {fields.filter(field => !field._destroy).map((field, questionOptionIdx) => (
            <QuestionOptionForm
              key={field.key}
              correct={field.correct}
              index={questionOptionIdx}
              questionIndex={index}
              register={register}
              onCorrect={() => markFieldAsCorrect(field.key)}
              onRemove={() => field.id ? markFieldAsDestroyed(field.id) : remove(questionOptionIdx)}
            />
          ))}
        </div>

        <TextareaField maxLength={1000} showHint={false} label={t('correct_feedback')} rows={10} {...register(`questions.${index}.answerCorrectFeedback`)} errors={formState.errors}/>
        <NumberInputField label={t('correct_typing')} min={0} max={10000} {...register(`questions.${index}.typingCorrect`, { valueAsNumber: true })} errors={formState.errors}/>
        <TextareaField maxLength={1000} showHint={false} label={t('wrong_feedback')} rows={10} {...register(`questions.${index}.answerWrongFeedback`)} errors={formState.errors}/>
        <NumberInputField label={t('wrong_typing')} min={0} max={10000} {...register(`questions.${index}.typingWrong`, { valueAsNumber: true })} errors={formState.errors}/>
      </div>
    </div>
  )
}

interface QuestionsFormProps {
  card: QuizCard
}

const QuestionsForm: React.FC<QuestionsFormProps> = ({ card }) => {
  const [ tabIndex, setTabIndex ] = React.useState<number>(0);
  const { register, setValue, setFocus, trigger, formState, clearErrors } = useFormContext<CardQuizValues>();
  const { fields, append, remove } = useFieldArray<CardQuizValues, 'questions', 'key'>({ name: 'questions', keyName: 'key' });
  const filteredFields = fields.filter(field => !field._destroy);
  const { t, i18n } = useTranslation();

  function appendQuestion() {
    // Store length before appending to avoid state conflicts.
    let questionIndex = filteredFields.length

    append({
      answerCorrectFeedback: 'Je antwoord is juist! ✅',
      typingCorrect: 4,
      answerWrongFeedback: 'Helaas, je antwoord is niet juist...⛔',
      typingWrong: 4,
      questionOptions: [],
      image: {blobId: null, _destroy: false}
    }, {
      // Prevent form from scrolling to fields behind hidden tab.
      shouldFocus: false
    })

    setTabIndex(questionIndex)
  }

  function removeQuestion(index: number) {
    remove(index)

    if (index > 0) {
      // Select previous tab (if any).
      setTabIndex(index - 1)
    }
  }

  function markFieldAsDestroyed(id: string, index: number) {
    setValue('questions', fields.map(({ key, ...field }) => ({
      ...field,
      _destroy: field.id === id ? true : field._destroy
    })));

    if (index > 0) {
      // Select previous tab (if any).
      setTabIndex(index - 1)
    }
  }

  React.useEffect(() => {
    if (filteredFields.length <= 0) {
      // Make sure the user always sees a question form, even if all questions are removed.
      setTimeout(() => {
        appendQuestion()
        clearErrors()
      }, 0)
    }
  }, [])

  return (
    <Tabs key={filteredFields.length} index={tabIndex} onChange={index => setTabIndex(index)}>
      <div className="border-b border-gray-200 flex justify-between">
        <TabList className="quiz-card-questions bg-transparent -mb-px space-x-4 h-14">
          {filteredFields.map((field, questionIdx) => (
            <CardFormTab key={field.key} className="min-w-[3rem]">
              {questionIdx + 1}
            </CardFormTab>
          ))}
        </TabList>

        <div className="flex flex-col items-end">
          <Button
            type="button"
            appearance={Appearance.Outline}
            size={Size.XS}
            onClick={() => appendQuestion()}
          >
            {t('add')}
          </Button>
          <ErrorMessage className="mt-2 text-sm text-red-600" name="questions" as="p" errors={formState.errors}/>
        </div>
      </div>

      <TabPanels className="mt-6">
        {filteredFields.map((field, questionIdx) => (
          <TabPanel key={field.key}>
            <QuestionForm
              question={(card.questions || []).find(question => question.id === field.id) ?? null}
              index={questionIdx}
              register={register}
              onRemove={() => field.id ? markFieldAsDestroyed(field.id, questionIdx) : removeQuestion(questionIdx)}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export interface QuizFormProps extends BaseFormProps<CardQuizValues> {
  card: QuizCard
  companyId: string
}

export const QuizForm: React.FC<QuizFormProps> = props => {
  let { onSubmit: unusedOnSubmit, ...formProps } = props
  let directUpload = useDirectUploadMutation()
  const { t, i18n } = useTranslation();

  let onSubmit: SubmitHandler<CardQuizValues> = async values => {
    let uploads = await Promise.all<{ index: number, signedBlobId?: string }>(
      values.questions
        .map((question, index) =>
          question?.image.blobId instanceof FileList
            ? directUpload(question.image.blobId![0]).then(signedBlobId => ({ index, signedBlobId }))
            : Promise.resolve({ index })
        )
    )

    return props.onSubmit({
      ...values, questions: values.questions.map((question, index) => ({
        ...question,
        image: { _destroy: question.image._destroy, blobId: uploads[index].signedBlobId },
        companyId: props.companyId
      }))
    })
  }

  return (
    <BaseForm<CardQuizValues> onSubmit={onSubmit} {...formProps}>
      {({ register, formState }) => (
      <>
        <SelectField label={t('minimum_correct_to_pass')} className="w-32 ml-auto" {...register('quizMessageThreshold', { valueAsNumber: true})} errors={formState.errors}>
          {Array.from({length: props.card?.questions?.length + 1 || 2}).map((_, index) => {
            return (<option key={index}>{index}</option>)
          })}
        </SelectField>
        <QuestionsForm card={props.card} />
      </>
      )}
    </BaseForm>
  )
}
