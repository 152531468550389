import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";
import { ChatAltIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { ThemesOverviewRoute_DeleteThemeMutation, ThemesOverviewRoute_DeleteThemeMutationVariables } from '../__generated__/ThemesOverviewRoute_DeleteThemeMutation';
import { ThemesOverviewRoute_ThemeTable_themes } from '../__generated__/ThemesOverviewRoute_ThemeTable_themes';
import { ThemesOverviewRoute_ThemeTableRow_theme } from '../__generated__/ThemesOverviewRoute_ThemeTableRow_theme';
import { ThemesOverviewRouteQuery } from '../__generated__/ThemesOverviewRouteQuery';
import { Button } from '../components/Button';
import useHasChanged from '../hooks/has-changed';
import { VFCwF } from '../types';
import {useTranslation} from "react-i18next";

interface ThemeTableChildRowProps {
  theme: ThemesOverviewRoute_ThemeTableRow_theme;
  onDelete: (id: string) => void
}

const ThemeTableChildRow: React.VFC<ThemeTableChildRowProps> = ({ theme, onDelete }) => {
  const location = useLocation()
  const { t, i18n } = useTranslation();

  return (
    <tr>
      <th
        scope="row"
        className="px-16 py-3 max-w-0 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900"
      >
        {theme.name}
      </th>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium">
        {theme.botModulesCount > 0 && (
          <div className="flex items-center space-x-2 w-40" aria-hidden="true">
            <div className="flex flex-shrink-0 -space-x-1">
              {Array(Math.min(theme.botModulesCount, 4))
                .fill(null)
                .map((_, i) => (
                  <div
                    key={i}
                    className="bg-gray-300 max-w-none h-6 w-6 flex items-center justify-center rounded-full ring-2 ring-white"
                  >
                    <ChatAltIcon className="h-4 w-4" />
                  </div>
                ))}
            </div>

            {theme.botModulesCount > 4 && (
              <span className="flex-shrink-0 text-xs leading-5 font-medium">
                {`+${theme.botModulesCount - 4}`}
              </span>
            )}
          </div>
        )}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium">
        <Menu>
          <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" />
          </MenuButton>
          <MenuList className="w-40">
            <MenuLink as={Link} to={`${theme.id}/edit`} state={{ backgroundLocation: location }}>
              {t('edit')}
            </MenuLink>
            <MenuItem onSelect={() => onDelete(theme.id)}>
              {t('delete')}
            </MenuItem>
          </MenuList>
        </Menu>
      </td>
    </tr>

  )
}

interface ThemeTableRowProps {
  childThemes: ThemesOverviewRoute_ThemeTableRow_theme[]
  theme: ThemesOverviewRoute_ThemeTableRow_theme
  onDelete: (id: string) => void
}

const ThemeTableRow: VFCwF<ThemeTableRowProps> = ({ childThemes, theme, onDelete }) => {
  const location = useLocation()
  const { t, i18n } = useTranslation();

  return (
    <AccordionItem as={({ children }) => <React.Fragment>{children}</React.Fragment>}>
      <tr>
        <AccordionButton as="th"
          scope="row"
          className="px-8 py-3 max-w-0 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900 hover:cursor-pointer"
        >
          {theme.name}
        </AccordionButton>
        <AccordionButton as="td" className="px-8 py-3 text-sm text-gray-500 font-medium hover:cursor-pointer">
          <div className="w-40"></div>
        </AccordionButton>
        <td className="px-8 py-3 text-sm text-gray-500 font-medium">
          <Menu>
            <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" />
            </MenuButton>
            <MenuList className="w-40">
              <MenuLink as={Link} to={`new?parent=${theme.id}`} state={{ backgroundLocation: location }}>
                {t('add')}
              </MenuLink>
              <MenuLink as={Link} to={`${theme.id}/edit`} state={{ backgroundLocation: location }}>
                {t('edit')}
              </MenuLink>
              <MenuItem onSelect={() => onDelete(theme.id)}>
                {t('delete')}
              </MenuItem>
            </MenuList>
          </Menu>
        </td>
      </tr>

      {childThemes.length > 0 && (
        <AccordionPanel as="tr">
          <td className="p-0" colSpan={3}>
            <table className="table-fixed">
              <tbody className="bg-gray-50 divide-y divide-gray-100">
                {childThemes.map(childTheme => (
                  <ThemeTableChildRow key={childTheme.id} theme={childTheme} onDelete={onDelete} />
                ))}
              </tbody>
            </table>
          </td>
        </AccordionPanel>
      )}
    </AccordionItem>
  )
}

ThemeTableRow.fragments = {
  theme: gql`
    fragment ThemesOverviewRoute_ThemeTableRow_theme on Theme {
      id
      name
      botModulesCount
    }
  `
}

interface ThemesTableProps {
  themes: ThemesOverviewRoute_ThemeTable_themes[]
  onDelete: (id: string) => void
}

const ThemeTable: VFCwF<ThemesTableProps> = ({ themes, onDelete }) => {
  return (
    <div className="overflow-auto border border-gray-200 rounded-lg relative">
      <table className="table-fixed min-w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="pl-8 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-8 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap w-11"
            >
              Bot Modules
            </th>
            <th
              scope="col"
              className="pr-8 py-3 border-b border-gray-200 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>

        <Accordion as="tbody" className="bg-white divide-y divide-gray-100" collapsible multiple>
          {themes.filter(theme => theme.parentId === null).map(theme => (
            <ThemeTableRow key={theme.id} theme={theme} childThemes={themes.filter(child => child.parentId === theme.id)} onDelete={onDelete} />
          ))}
        </Accordion>
      </table>
    </div>
  );
}

ThemeTable.fragments = {
  themes: gql`
    fragment ThemesOverviewRoute_ThemeTable_themes on Theme {
      id
      parentId
      ...ThemesOverviewRoute_ThemeTableRow_theme
    }

    ${ThemeTableRow.fragments.theme}
  `
}

interface ThemesOverviewRouteProps {
  companyId: string;
}

const ThemesOverviewRoute: React.VFC<ThemesOverviewRouteProps> = ({ companyId }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);

  const { data, loading, error, refetch } = useQuery<ThemesOverviewRouteQuery>(gql`
    query ThemesOverviewRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id

        themes {
          ...ThemesOverviewRoute_ThemeTable_themes
        }
      }

    }

    ${ThemeTable.fragments.themes}
  `, {
    variables: {
      companyId
    }
  })

  const [deleteTheme] = useMutation<ThemesOverviewRoute_DeleteThemeMutation, ThemesOverviewRoute_DeleteThemeMutationVariables>(gql`
    mutation ThemesOverviewRoute_DeleteThemeMutation($input: DeleteThemeMutationInput!) {
      deleteTheme(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['ThemesOverviewRouteQuery']
  })

  React.useEffect(() => {
    if (hasStateChanged && !state?.backgroundLocation) {
      // Refetch themes when the modal is closed by a navigate action.
      refetch()
    }
  })

  function onThemeDelete(id: string) {
    deleteTheme({ variables: { input: { id } } })
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            Themes
          </h1>
          <div className="flex space-x-3">
            <Button onClick={() => navigate('new', { state: { backgroundLocation: location } })}>
              Create Theme
            </Button>
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (
          <ThemeTable themes={data!.company!.themes} onDelete={onThemeDelete} />
        )}
      </main>
    </div>
  );
}

export default ThemesOverviewRoute;
