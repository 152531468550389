import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import {SlideOver} from "../components/SlideOver";
import {UserForm, UserValues} from "../components/UserForm";
import {UserAttachForm, UserAttachValues} from "../components/UserAttachForm";
import {useTranslation} from "react-i18next";

interface UserCreateRouteProps {
	companyId: string;
}

const UsersAttachRoute: React.FC<UserCreateRouteProps> = ({ companyId }) => {
	const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery(gql`
    query UsersAttachRouteQuery($companyId: ID!) {
      users(id: $companyId) {
        id
        username
        email
      }
    }
  `, {
    variables: {
      companyId
    }
  });

  const [attachUser] = useMutation(gql`
    mutation UsersCreateRoute_AttachUserMutation($input: AttachUserMutationInput!) {
      attachUser(input: $input) {
        user {
          id
        }
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'UsersOverviewRouteQuery',
    ]
  })

  function onCancel() {
			navigate(`/${companyId}/users`)
  }

  async function onSubmit(values: UserAttachValues) {
		let { data } = await attachUser({ variables: { input: { companyId, ...values } } })
		navigate({pathname: `/${companyId}/users`}, {replace: true})
  }

	return (
		<SlideOver title={t('attach_user')} onClose={onCancel}>
      {data && data.users &&
        <UserAttachForm
          users={data.users}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      }
		</SlideOver>
	)
}

export default UsersAttachRoute;
