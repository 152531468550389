import * as React from 'react';
import { gql } from '@apollo/client'
import { SubmitHandler, UseFormRegister, FormStateProxy, FieldErrors, useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { Tabs, TabList, TabPanels, TabPanel } from "@reach/tabs"
import { TrashIcon } from '@heroicons/react/outline'
import { CardType } from '../../__generated__/globalTypes'
import { Button, Appearance, Intent, Size } from '../Button';
import { CardFormTab } from '../CardFormTabs';
import { FileInputField } from '../FileInputField';
import { NumberInputField } from '../NumberInputField';
import { TextareaField } from '../textarea-field';
import useDirectUploadMutation from '../../hooks/direct-upload-mutation';
import { OpenCard, OpenQuestion } from '../../types';
import { BaseForm, BaseFormProps } from './base-form';
import { CardValues } from '.';
import { ErrorMessage } from '@hookform/error-message';
import { ImageInputField, imageTypeTest, imageSizeTest } from '../ImageInputField';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

export const OpenFormSchema = {
  questions: yup.array().of(
    yup.object({
      question: yup.string().required().label("Question text"),
      image: yup.object({
        blobId: yup.mixed().nullable().test(imageTypeTest).test(imageSizeTest).label("Image"),
      }),
      openQuestionFeedback: yup.string().required().label("Feedback"),
      openQuestionTyping: yup.number().integer().required().min(0).max(1000).label("Typing"),
    })
  ).required().min(1).label("Questions")
}

export const OpenFormFragment = gql`
  fragment OpenFormFragment on OpenCard {
    questions {
      id
      question
      imageUrl
      openQuestionFeedback
      openQuestionTyping
    }
  }
`

interface QuestionValues {
  id: string;
  question: string;
  image: {
    blobId: FileList | null | string | undefined | string
    _destroy: boolean
  }
  openQuestionFeedback: string;
  openQuestionTyping: number;
  _destroy: boolean;
}

export interface CardOpenValues extends CardValues {
  cardType: CardType.OPEN
  questions: QuestionValues[]
}

export function defaultValuesForOpenCard(card: OpenCard | null): CardOpenValues {
  return {
    cardType: CardType.OPEN,
    title: card?.title || '',
    questions: (card?.questions || []).map(question => ({
      id: question.id,
      question: question.question,
      image: {
        blobId: question.imageUrl || null,
        _destroy: false
      },
      openQuestionFeedback: question.openQuestionFeedback,
      openQuestionTyping: question.openQuestionTyping,
      _destroy: false
    }))
  }
}

interface QuestionFormProps {
  index: number;
  question: OpenQuestion | null;
  register: UseFormRegister<CardOpenValues>;
  onRemove: () => void;
}

const QuestionForm: React.FC<QuestionFormProps> = ({ index, question, register, onRemove }) => {
  const { setValue, formState } = useFormContext<CardOpenValues>();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    register(`questions.${index}._destroy`)
  }, [register])

  return (
    <div>
      <div className="flex justify-end">
        <Button type="button" appearance={Appearance.Outline} intent={Intent.Danger} size={Size.XS} onClick={() => onRemove()}>
          <TrashIcon className="h-4 w-4" />
          <span className="ml-2">{t('remove')}</span>
        </Button>
      </div>
      <div className="space-y-6">
        <TextareaField maxLength={1000} showHint={true} label={t('question')} {...register(`questions.${index}.question`)} errors={formState.errors} />
        <ImageInputField
          preview={question?.imageUrl}
          onRemove={() => setValue(`questions.${index}.image`, {blobId: null, _destroy: true}, { shouldValidate: true })}
          errors={formState.errors}
          {...register(`questions.${index}.image.blobId`)}
        />
        <TextareaField maxLength={1000} showHint={false} label={t('feedback')} rows={10} {...register(`questions.${index}.openQuestionFeedback`)} errors={formState.errors}/>
        <NumberInputField label={t('typing')} min={0} max={1000} {...register(`questions.${index}.openQuestionTyping`, { valueAsNumber: true })} errors={formState.errors} />
      </div>
    </div>
  )
}

interface QuestionsFormProps {
  card: OpenCard
}

const QuestionsForm: React.FC<QuestionsFormProps> = ({ card }) => {
  const [ tabIndex, setTabIndex ] = React.useState<number>(0);
  const { register, setValue, trigger, clearErrors, formState  } = useFormContext<CardOpenValues>();
  const { fields, append, remove } = useFieldArray<CardOpenValues, 'questions', 'key'>({ name: 'questions', keyName: 'key' });
  const filteredFields = fields.filter(field => !field._destroy);
  const { t, i18n } = useTranslation();

  function appendQuestion() {
    // Store length before appending to avoid state conflicts.
    let questionIndex = filteredFields.length

    append({
      openQuestionTyping: 4,
      image: {blobId: null, _destroy: false},
    }, {
      // Prevent form from scrolling to fields behind hidden tab.
      shouldFocus: false
    })

    setTabIndex(questionIndex)
  }

  function removeQuestion(index: number) {
    remove(index)

    if (index > 0) {
      // Select previous tab (if any).
      setTabIndex(index - 1)
    }
  }

  function markFieldAsDestroyed(id: string, index: number) {
    setValue('questions', fields.map(({ key, ...field }) => ({
      ...field,
      _destroy: field.id === id ? true : field._destroy
    })));

    if (index > 0) {
      // Select previous tab (if any).
      setTabIndex(index - 1)
    }
  }

  React.useEffect(() => {
    if (filteredFields.length <= 0) {
      // Make sure the user always sees a question form, even if all questions are removed.
      setTimeout(() => {
        appendQuestion()
        clearErrors()
      }, 0)
    }
  }, [])

  return (
    <Tabs key={filteredFields.length} index={tabIndex} onChange={index => setTabIndex(index)}>
      <div className="border-b border-gray-200 flex justify-between">
        <TabList className="bg-transparent -mb-px space-x-4 h-14">
          {filteredFields.map((field, questionIdx) => (
            <CardFormTab key={field.key} className="min-w-[3rem]">
              {questionIdx + 1}
            </CardFormTab>
          ))}
        </TabList>

        <div className="flex flex-col items-end">
          <Button
            type="button"
            appearance={Appearance.Outline}
            size={Size.XS}
            onClick={() => appendQuestion()}
          >
            {t('add')}
          </Button>
          <ErrorMessage errors={formState.errors} className="mt-2 text-sm text-red-600" name="questions" as="p" />
        </div>
      </div>

      <TabPanels className="mt-6">
        {filteredFields.map((field, questionIdx) => (
          <TabPanel key={field.key}>
            <QuestionForm
              key={field.key}
              question={(card.questions || []).find(question => question.id === field.id) ?? null}
              index={questionIdx}
              register={register}
              onRemove={() => field.id ? markFieldAsDestroyed(field.id, questionIdx) : removeQuestion(questionIdx)}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export interface OpenFormProps extends BaseFormProps<CardOpenValues> {
  card: OpenCard
  companyId: string
}

export const OpenForm: React.FC<OpenFormProps> = props => {
  let { onSubmit: unusedOnSubmit, ...formProps } = props
  let directUpload = useDirectUploadMutation()

  let onSubmit: SubmitHandler<CardOpenValues> = async values => {
    let uploads = await Promise.all<{ index: number, signedBlobId?: string }>(
      values.questions
        .map((question, index) =>
             question?.image.blobId instanceof FileList
            ? directUpload(question.image.blobId![0]).then(signedBlobId => ({ index, signedBlobId }))
            : Promise.resolve({ index })
        )
    )

    return props.onSubmit({
      ...values,
      questions: values.questions.map((question, index) => ({
        ...question,
        image: { _destroy: question.image._destroy, blobId: uploads[index].signedBlobId },
        companyId: props.companyId
      }))
    })
  }

  return (
    <BaseForm<CardOpenValues> onSubmit={onSubmit} {...formProps}>
      <QuestionsForm card={props.card} />
    </BaseForm>
  )
}
