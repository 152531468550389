import * as React from 'react'
import cx from 'clsx'

export enum Appearance {
  Primary = 'primary',
  // Secondary = 'secondary',
  Outline = 'outline',
}

export enum Intent {
  None = 'none',
  // Success = 'success',
  // Warning = 'warning',
  Danger = 'danger',
}

export enum Size {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: Appearance;
  intent?: Intent;
  size?: Size;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  appearance = Appearance.Primary,
  intent = Intent.None,
  size = Size.M,
  children,
  className,
  ...props
}, ref) => {
  return (
    <button
      ref={ref}
      className={cx(
        'inline-flex items-center border shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
        {
          'border-transparent text-white': appearance === Appearance.Primary,
          'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 disabled:bg-blue-300 disabled:hover:bg-blue-200': appearance === Appearance.Primary && intent === Intent.None,
          'bg-red-600 hover:bg-red-700 focus:ring-red-500': appearance === Appearance.Primary && intent === Intent.Danger,

          'bg-white': appearance === Appearance.Outline,
          'border-gray-300 text-gray-700 hover:bg-gray-50 focus:ring-blue-500': appearance === Appearance.Outline && intent === Intent.None,
          'border-red-300 text-red-600 hover:bg-red-50 focus:ring-red-500': appearance === Appearance.Outline && intent === Intent.Danger,

          'px-2.5 py-1.5 text-xs rounded': size === Size.XS,
          'px-3 py-2 text-sm leading-4 rounded-md': size === Size.S,
          'px-4 py-2 text-sm rounded-md': size === Size.M,
          'px-4 py-2 text-base rounded-md': size === Size.L,
          'px-6 py-3 text-base rounded-md': size === Size.XL,
        }
      )}
      {...props}
    >
      {children}
    </button>
  )
})
