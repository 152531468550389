import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client';
import { DialogFlowForm_company } from '../__generated__/DialogFlowForm_company';
import { DialogFlowForm_UpdateCompanyMutation, DialogFlowForm_UpdateCompanyMutationVariables } from '../__generated__/DialogFlowForm_UpdateCompanyMutation';
import { Button } from './Button';
import { SelectField } from './select-field';
import { TextareaField } from './textarea-field';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const DialogFlowSchema = yup.object({
  dialogflow: yup.object({
    projectId: yup.string().required().label("Project ID"),
    clientEmail: yup.string().email().required().label("Client Email"),
    privateKey: yup.string().required().label("Private key"),
  }).required()
})

export interface DialogFlowValues {
  dialogflow: {
    projectId: string
    clientEmail: string
    privateKey: string
  }
}

export interface DialogFlowFormProps {
  company: DialogFlowForm_company
}

export const DialogFlowForm: VFCwF<DialogFlowFormProps> = ({ company }) => {
  const defaultValues: DefaultValues<DialogFlowValues> = {
    dialogflow: {
      projectId: company.dialogflow?.projectId ?? '',
      clientEmail: company.dialogflow?.clientEmail ?? '',
      privateKey: company.dialogflow?.privateKey ?? '',
    },
  }
  const { register, handleSubmit, formState } = useForm<DialogFlowValues>({ defaultValues, resolver: yupResolver(DialogFlowSchema) })

  const [updateCompany] = useMutation<DialogFlowForm_UpdateCompanyMutation, DialogFlowForm_UpdateCompanyMutationVariables>(
    gql`
      mutation DialogFlowForm_UpdateCompanyMutation($input: UpdateCompanyMutationInput!) {
        updateCompany(input: $input) {
          company {
            id
          }
        }
      }
    `
  )

  const onSubmit: SubmitHandler<DialogFlowValues> = async (values) => {
    await updateCompany({
      variables: {
        input: { id: company.id, ...values }
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="space-y-6">
            <TextInputField label="Project ID" {...register('dialogflow.projectId')} errors={formState.errors} />
            <TextInputField label="Client Email" {...register('dialogflow.clientEmail')} errors={formState.errors} />
            <TextareaField showHint={false} label="Private Key" {...register('dialogflow.privateKey')} errors={formState.errors} />
          </div>
        </div>

        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            Save
          </Button>
        </div>
      </div>
    </form>
  )
}

DialogFlowForm.fragments = {
  company: gql`
    fragment DialogFlowForm_company on Company {
      id
      dialogflow {
        projectId
        clientEmail
        privateKey
      }
    }
  `
}
