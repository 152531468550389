export function getCurrentCSRFToken(): string | undefined | null {
  return window.document.querySelector("meta[name=csrf-token]")?.getAttribute('content');
}

export async function getFromBackend(url: string, headers: any = {} ): Promise<any> {
  const token = getCurrentCSRFToken();

  if (token) {
    headers['X-CSRF-TOKEN'] = token;
  }

  return await fetch(url, {
    method: 'GET',
    headers: headers
  });
}

export async function postToBackend(url: string, data: any): Promise<any> {
  let formData = new FormData();
  Object.keys(data).forEach(o => {
    formData.append(o, data[o])
  })

  const token = getCurrentCSRFToken();
  let headers: any = {}

  if (token) {
    headers['X-CSRF-TOKEN'] = token;
  }

  return await fetch(url, {
    method: 'POST',
    headers: headers,
    body: formData
  });
}

export function sendTemplate(evt: any, templateId: string | null): void {
  if (!templateId) return

  postToBackend('/whatsapp/message', { template_id: templateId });
}

export function sendCard(evt: any, cardId: string | null): void {
  if (!cardId) return;

  postToBackend('/whatsapp/message', { card_id: cardId });
}

export function sendModule(evt: any, moduleId: string | null | undefined): void {
  if (!moduleId) return;

  postToBackend('/whatsapp/module', { module_id: moduleId });
}
