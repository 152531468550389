import * as React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { QaModulesDetailsRouteQuery } from '../__generated__/QaModulesDetailsRouteQuery';
import { QaModulesDetailsRoute_UpdateQaModuleMutation, QaModulesDetailsRoute_UpdateQaModuleMutationVariables } from '../__generated__/QaModulesDetailsRoute_UpdateQaModuleMutation';

import { CardType } from '../__generated__/globalTypes';
import { CardForm, CardValues } from '../components/card-form';
import { CardList } from '../components/CardList';
import { Button, Appearance, Intent } from '../components/Button';
import { sendModule } from '../libraries/librequestutils';
import {
  QaModulesDetailsRoute_CreateCardMutation,
  QaModulesDetailsRoute_CreateCardMutationVariables
} from "../__generated__/QaModulesDetailsRoute_CreateCardMutation";
import {
  QaModulesDetailsRoute_UpdateCardMutation,
  QaModulesDetailsRoute_UpdateCardMutationVariables
} from "../__generated__/QaModulesDetailsRoute_UpdateCardMutation";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import cx from "clsx";
import {
  BotModulesDetailsRoute_DeleteCardMutation,
  BotModulesDetailsRoute_DeleteCardMutationVariables
} from "../__generated__/BotModulesDetailsRoute_DeleteCardMutation";
import {
  QaModulesDetailsRoute_DeleteCardMutation,
  QaModulesDetailsRoute_DeleteCardMutationVariables
} from "../__generated__/QaModulesDetailsRoute_DeleteCardMutation";
import {useTranslation} from "react-i18next";

interface QaModulesDetailsRouteProps {
  companyId: string;
}

const QaModulesDetailsRoute: React.VFC<QaModulesDetailsRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { qaModuleId } = useParams<'qaModuleId'>();
  const { cardId } = useParams<'cardId'>();
  const [selected, setSelected] = React.useState<{ id: null, botModuleId: string, cardType: CardType } | { id: string | null, botModuleId: string, cardType: CardType, title: string } | null>(null);
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery<QaModulesDetailsRouteQuery>(gql`
    query QaModulesDetailsRouteQuery($qaModuleId: ID!) {
      qaModule(id: $qaModuleId) {
        id
        name
        companyId
        cards {
          id
          cardType
          title
        }
      }
    }
  `, {
    variables: {
      qaModuleId: qaModuleId as string
    }
  });


  const [updateQaModule] = useMutation<QaModulesDetailsRoute_UpdateQaModuleMutation, QaModulesDetailsRoute_UpdateQaModuleMutationVariables>(gql`
    mutation QaModulesDetailsRoute_UpdateQaModuleMutation($input: UpdateQaModuleMutationInput!) {
      updateQaModule(input: $input) {
        qaModule {
          id
        }
      }
    }
  `)

  const [createCard] = useMutation<QaModulesDetailsRoute_CreateCardMutation, QaModulesDetailsRoute_CreateCardMutationVariables>(gql`
    mutation QaModulesDetailsRoute_CreateCardMutation($input: CreateCardMutationInput!) {
      createCard(input: $input) {
        card {
          id
          botModuleId
          cardType
          title
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['QaModulesDetailsRouteQuery', 'CardFormQuery']
  })

  const [updateCard] = useMutation<QaModulesDetailsRoute_UpdateCardMutation, QaModulesDetailsRoute_UpdateCardMutationVariables>(gql`
    mutation QaModulesDetailsRoute_UpdateCardMutation($input: UpdateCardMutationInput!) {
      updateCard(input: $input) {
        card {
          id
          qaModuleId
          cardType
          title
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['QaModulesDetailsRouteQuery', 'CardFormQuery']
  })

  const [deleteCard] = useMutation<QaModulesDetailsRoute_DeleteCardMutation, QaModulesDetailsRoute_DeleteCardMutationVariables>(gql`
    mutation QaModulesDetailsRoute_DeleteCardMutation($input: DeleteCardMutationInput!) {
      deleteCard(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['QaModulesDetailsRouteQuery', 'CardFormQuery']
  })

  async function handleCardDelete() {
    if (selected!.id) {
      await deleteCard({ variables: { input: { id: selected!.id } } }).then(() => setSelected(null));
      await deleteCard({ variables: { input: { id: selected!.id } } })
    }

    navigate(`/${companyId}/qa_modules/${qaModuleId}`)
  }

   async function handleCardFormSubmit(values: CardValues) {
    let cardData = await (selected!.id
      ? updateCard({
          variables: {
            input: { id: selected!.id, ...values }
          }
        }).then(({ data }) => setSelected({
          id: data!.updateCard!.card.id, botModuleId: qaModuleId as string, cardType: data!.updateCard!.card.cardType, title: data!.updateCard!.card.title
        }))
      : createCard({
          variables: {
            input: { qaModuleId: selected!.botModuleId, ...values }
          }
        }) .then(({data}) => navigate(`/${companyId}/qa_modules/${qaModuleId}/cards/${data!.createCard!.card.id}`))
    )
  }

  function handleCardsSorted(cards: { id: string }[]) {
    updateQaModule({
      variables: { input: { id: qaModuleId as string, cardIds: cards.map(card => card.id) } },
      update: (cache, { data: { updateQaModule: { qaModule } }  }) => {
        cache.modify({
          id: cache.identify(qaModule),
          fields: {
            cards() {
              return cards;
            }
          }
        })
      }
    })
  }

  function onCreateClick(cardType: CardType) {
    setSelected({ id: null, botModuleId: qaModuleId as string, cardType });
  }

  React.useEffect(() => {
    if (!cardId) return;

    const card = data?.qaModule?.cards?.find((card) => card.id === cardId) || null
    if (card) {
      setSelected({
        id: card.id, botModuleId: qaModuleId as string, cardType: card.cardType, title: card.title
      });
    }
  }, [data, cardId])

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <div className="flex items-center text-2xl font-semibold text-gray-300">
            <Link to={`/${companyId}/qa_modules`} className="hover:text-gray-400">
              {t('qa_modules')}
            </Link>

            <svg className="flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>

            <h1 className="text-gray-900 truncate">
              {t('editing')} {data ? data?.qaModule!.name : '...'}
            </h1>
          </div>

          <Menu as="div" className="ml-4 relative flex-shrink-0">
            <div>
              <Menu.Button className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                {t('create')}
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {['image', 'text', 'video'].map(cardType => (
                    <Menu.Item key={cardType}>
                      {({ active }) => (
                        <button
                          className={cx(active ? 'text-blue-600' : 'text-gray-700', 'group flex items-center w-full text-left px-4 py-2 text-sm capitalize')}
                          onClick={() => onCreateClick(cardType.toUpperCase() as CardType)}
                        >
                          {cardType}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (!data || !data?.qaModule || data?.qaModule?.companyId !== companyId) ? (
          <p>{t('not_found')}</p>
        ) : (
          <div className="grid grid-cols-4 gap-x-6">
            <div className="col-span-1">
              <h2 className="text-lg font-semibold text-gray-900">
                {t('cards')}
              </h2>

              <CardList
                routePart="qa_modules"
                className="mt-2"
                companyId={companyId}
                botModule={data?.qaModule}
                onSorted={handleCardsSorted}
              />
            </div>

            <div className="col-span-3">
              {selected ? (
                <React.Fragment>
                  <h2 className="text-lg font-semibold text-gray-900">
                    {selected.id ? `${t('editing')} ${selected.title}` : 'Creating new card'}
                  </h2>

                  <CardForm
                    className="mt-2"
                    card={selected}
                    onSubmit={handleCardFormSubmit}
                    onCancel={handleCardDelete}
                    onDelete={handleCardDelete}
                    companyId={companyId}
                  />
                </React.Fragment>
              ) : (
                <div>
                  {t('please_select')}
                </div>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  )
}

export default QaModulesDetailsRoute;
