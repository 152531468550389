import React from 'react';
import {createSearchParams, Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client'
import {
  QaModulesOverviewRouteQuery,
  QaModulesOverviewRouteQuery_company_qaModules
} from '../__generated__/QaModulesOverviewRouteQuery';

import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";
import useHasChanged from "../hooks/has-changed";
import Popover from "../components/Popover";
import {CheckCircleIcon, ExclamationCircleIcon} from "@heroicons/react/outline";
import {Select} from "../components/select-field";
import {Button} from "../components/Button";
import {VFCwF} from "../types";
import {ChevronLeftIcon, ChevronRightIcon, DotsVerticalIcon} from "@heroicons/react/solid";
import {BotUsersOverviewRouteQuery_company_botUsers} from "../__generated__/BotUsersOverviewRouteQuery";
import cx from "clsx";
import {
  QaModulesOverviewRoute_DeleteQaModuleMutation,
  QaModulesOverviewRoute_DeleteQaModuleMutationVariables
} from "../__generated__/QaModulesOverviewRoute_DeleteQaModuleMutation";
import {SearchBox} from "../components/Searchbox";
import {useTranslation} from "react-i18next";

interface QaModulesOverviewRouteProps {
  companyId: string
}

interface QaModuleTableProps {
  companyId: string;
  qaModules?: QaModulesOverviewRouteQuery_company_qaModules[]
}

const QaModulesOverviewRoute: React.VFC<QaModulesOverviewRouteProps> = ({ companyId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);

  const searchParams = new URLSearchParams((state?.backgroundLocation || location).search);
  const highlightIds = searchParams.get('highlight')

  const createParams = {}

  const { data, loading, error, refetch } = useQuery<QaModulesOverviewRouteQuery>(gql`
    query QaModulesOverviewRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        name
        qaModules {
          id
          name
          tagList
        }
      }
    }
  `, {
    variables: {
      companyId
    }
  });

  const [deleteQaModule] = useMutation<QaModulesOverviewRoute_DeleteQaModuleMutation, QaModulesOverviewRoute_DeleteQaModuleMutationVariables>(gql`
    mutation QaModulesOverviewRoute_DeleteQaModuleMutation($input: DeleteQaModuleMutationInput!) {
      deleteQaModule(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['QaModulesOverviewRouteQuery']
  })

  function onSearchSelect(id: string) {
    navigate(`/${companyId}/qa_modules/${id}`)
  }

  function onQaModuleDelete(id: string) {
    deleteQaModule({ variables: { input: { id } } })
  }

  React.useEffect(() => {
    if (hasStateChanged && !state?.backgroundLocation) {
      // Refetch bot modules when the modal is closed by a navigate action.
      refetch()
    }
  });

  const QaModuleTable: React.FunctionComponent<QaModuleTableProps> = ({ companyId, qaModules}) => {
    return (
      <div className="overflow-hidden border border-gray-200 rounded-lg">
        <table className="min-w-full">
          <thead className="bg-gray-50">
          <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
            <th scope="col" className="pl-8 py-3">
              {t('name')}
            </th>
            <th scope="col" className="pr-8 py-3 text-right">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-100">
          <>
            {qaModules ? [...qaModules].sort((a, b) => a?.name?.localeCompare(b?.name))
              .map(qaModule =>
                <tr key={qaModule?.id} className={cx({"animate-fade": highlightIds?.includes(qaModule.id)})}>
                  <th
                    scope="row"
                    className="px-8 py-3 max-w-0 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900"
                  >
                    <Link className="block truncate hover:text-gray-600" to={qaModule.id}>
                      {qaModule.name}
                      {(qaModule.tagList && qaModule.tagList.length > 3) && <span className="text-blue-200"> ({qaModule.tagList})</span>}
                    </Link>

                  </th>
                  <td className="px-8 py-3 text-sm text-gray-500 font-medium space-x-3">
                    <Menu>
                      <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                        <span className="sr-only">Open options</span>
                        <DotsVerticalIcon className="h-5 w-5" />
                      </MenuButton>
                      <MenuList className="w-40">
                        <MenuLink as={Link} to={qaModule.id}>
                          {t('details')}
                        </MenuLink>
                        <MenuLink as={Link} to={`${qaModule.id}/edit`} state={{ backgroundLocation: location }}>
                          {t('edit')}
                        </MenuLink>
                        <MenuItem onSelect={() => onQaModuleDelete(qaModule.id)}>
                          {t('delete')}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </td>
              </tr>) : (null)
            }
            {!qaModules?.length && (
              <tr>
                <td className="px-8 py-3 text-sm text-gray-500" colSpan={5}>
                  {t('not_found')}
                </td>
              </tr>
            )}
          </>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            {t('qa_modules')}
          </h1>
          <div className="flex items-center space-x-3">
            <SearchBox
              className="w-64"
              options={data?.company!.qaModules ?? []}
              disabled={loading}
              onSelect={onSearchSelect}
            />
            <Button disabled={loading} onClick={() => navigate({
              pathname: 'new',
              ...createParams
            }, { state: { backgroundLocation: location } })}>
              {t('create_question_answer_module')}
            </Button>
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (
          <QaModuleTable
            companyId={companyId}
            qaModules={data?.company!.qaModules}
          />
        )}
      </main>
    </div>
  );
}

export default QaModulesOverviewRoute;
