import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FoldersUpdateRoute_UpdateFolderMutation, FoldersUpdateRoute_UpdateFolderMutationVariables } from '../__generated__/FoldersUpdateRoute_UpdateFolderMutation';
import { FoldersUpdateRouteQuery } from '../__generated__/FoldersUpdateRouteQuery';
import { FolderForm, FolderValues } from '../components/FolderForm';
import { SlideOver } from '../components/SlideOver';
import {useTranslation} from "react-i18next";

interface FoldersUpdateRouteProps {
  companyId: string;
}

const FoldersUpdateRoute: React.FC<FoldersUpdateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { folderId } = useParams<'folderId'>();
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery<FoldersUpdateRouteQuery>(gql`
    query FoldersUpdateRouteQuery($folderId: ID!) {
      folder(id: $folderId) {
        id
        companyId
        ...FolderForm_folder
      }
    }

    ${FolderForm.fragments.folder}
  `, {
    variables: {
      folderId: folderId as string
    }
  });

  const [updateFolder] = useMutation<FoldersUpdateRoute_UpdateFolderMutation, FoldersUpdateRoute_UpdateFolderMutationVariables>(gql`
    mutation FoldersUpdateRoute_UpdateFolderMutation($input: UpdateFolderMutationInput!) {
      updateFolder(input: $input) {
        folder {
          id
        }
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/bot_modules`)
  }

  async function onSubmit(values: FolderValues) {
    let { data } = await updateFolder({ variables: { input: { id: folderId as string, ...values } } })
    // navigate(`/${companyId}/bot_modules?folder=${data!.updateFolder!.folder.id}`)
    navigate(`/${companyId}/bot_modules`)
  }

  return (
    <SlideOver title={t('edit_folder')} onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (!data || !data.folder || data.folder.companyId !== companyId) ? (
        <p>{t('not_found')}</p>
      ) : (
        <FolderForm
          folder={data.folder}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </SlideOver>
  )
}

export default FoldersUpdateRoute;
