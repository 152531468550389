import * as React from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import { BotModulesCloneRouteQuery } from '../__generated__/BotModulesCloneRouteQuery';
import { BotModuleCloneForm } from '../components/BotModuleCloneForm';
import {BotModulesCreateRouteQuery} from "../__generated__/BotModulesCreateRouteQuery";
import {BotModuleForm} from "../components/BotModuleForm";
import {
  BotModulesCreateRoute_CreateBotModuleMutation,
  BotModulesCreateRoute_CreateBotModuleMutationVariables
} from "../__generated__/BotModulesCreateRoute_CreateBotModuleMutation";

export interface BotModulesCloneRouteProps {
  companyId: string;
}

export const loadBotModules = (botModuleId: string) => {
  const data = useQuery<BotModulesCloneRouteQuery>(gql`
    query BotModulesCloneRouteQuery($botModuleId: ID!) {
      botModule(id: $botModuleId) {
        id
        folderId
        ...BotModuleCloneForm_botModule
      }

      companies {
        ...BotModuleCloneForm_companies
      }
    }

    ${BotModuleCloneForm.fragments.botModule}
    ${BotModuleCloneForm.fragments.companies}
  `, {
    variables: {
      botModuleId: botModuleId as string
    }
  })

  return data;
}

export const loadBotModulesForCompany = (companyId: string) => {
  const data = useQuery<BotModulesCreateRouteQuery>(gql`
    query BotModulesCreateRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id

        folders {
          ...BotModuleForm_folders
        }

        themes {
          ...BotModuleForm_themes
        }
      }
    }

    ${BotModuleForm.fragments.folders}
    ${BotModuleForm.fragments.themes}
  `, {
    variables: {
      companyId
    }
  });

  return data;
}

export const makeCreateBotModuleMutation = () => {
  const result = useMutation<BotModulesCreateRoute_CreateBotModuleMutation, BotModulesCreateRoute_CreateBotModuleMutationVariables>(gql`
    mutation BotModulesCreateRoute_CreateBotModuleMutation($input: CreateBotModuleMutationInput!) {
      createBotModule(input: $input) {
        botModule {
          id
          folderId
        }
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'BotModulesOverviewRouteQuery',
    ]
  })

  return result;
}
