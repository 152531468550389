import * as React from 'react';
import { gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'clsx'
import { CardList_botModule } from '../__generated__/CardList_botModule';
import { CardListItem_card } from '../__generated__/CardListItem_card';
import { FCwF } from '../types';

export interface CardListItemProps {
  card: CardListItem_card
  companyId: string
  botModule: CardList_botModule,
  routePart: string | 'bot_modules'
}

export const CardListItem: FCwF<CardListItemProps> = ({ companyId, botModule, card, routePart }) => {
  const { cardId } = useParams<'cardId'>();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: card.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      className="relative flex bg-white hover:bg-gray-50 rounded-md shadow-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="flex-shrink-0 flex items-center justify-center w-16 bg-blue-200 text-blue-500 text-xs font-medium rounded-l-md">
        {card.cardType}
      </div>

      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 rounded-r-md overflow-hidden">
        <Link
          to={`/${companyId}/${routePart}/${botModule.id}/cards/${card.id}`}
          className={`flex-1 px-4 py-2 text-sm text-left truncate focus:outline-none ${card.id === cardId && "bg-blue-100"}`}
        >
          <span className="absolute inset-0" aria-hidden></span>
          {card.title}
        </Link>
      </div>
    </li>
  )
}

CardListItem.fragments = {
  card: gql`
    fragment CardListItem_card on Card {
      id
      cardType
      title
    }
  `
}

export interface CardListProps {
  botModule: any
  companyId: string
  className?: string;
  routePart: string | 'bot_modules',
  onSorted: (cards: CardListItem_card[]) => void;
}

export const CardList: FCwF<CardListProps> = ({ companyId, botModule, className, onSorted, routePart }) => {
  const [items, setItems] = React.useState<CardListItem_card[]>(botModule.cards ?? []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  React.useEffect(() => {
    setItems(botModule.cards ?? []);
  }, [botModule])

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active.id !== over!.id) {
      const oldIndex = items.findIndex((card) => card.id === active.id)
      const newIndex = items.findIndex((card) => card.id === over!.id)
      const sorted = arrayMove(items, oldIndex, newIndex)

      setItems(sorted)
      onSorted(sorted)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items}>
        <ul className={cx('space-y-2', className)}>
          {items.map(item => (
            <CardListItem key={item.id} companyId={companyId} botModule={botModule} card={item} routePart={routePart} />
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  )
}

CardList.fragments = {
  botModule: gql`
    fragment CardList_botModule on BotModule {
      id
      cards {
        id

        ...CardListItem_card
      }
    }

    ${CardListItem.fragments.card}
  `,
  qatModule: gql`
    fragment CardList_qaModule on QaModule {
      id
      cards {
        id

        ...CardListItem_card
      }
    }

    ${CardListItem.fragments.card}
  `
}
