import * as React from 'react'
import { useId } from '@reach/auto-id'
import {ErrorMessage} from "@hookform/error-message";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import { DepartmentManageShopCompaniesFormQuery_company_departmentShopCompanies } from 'app/javascript/__generated__/DepartmentManageShopCompaniesFormQuery';

export interface CheckboxListProps {
  name: string
  setValue: any
  getValues: any
  id?: string
  className?: string
  label?: string
  errors?: any
  children: any
  style?: any
  items?: DepartmentManageShopCompaniesFormQuery_company_departmentShopCompanies[]
}

export const CheckboxList = React.forwardRef<HTMLInputElement, CheckboxListProps>((props, ref) => {
  const [filter, setFilter] = useState("");
  const id = useId(props.id);
  const { id: unusedId, setValue, getValues, className, style, label, name, errors, items, onChange } = props
  const { t, i18n } = useTranslation();

  let itemIds = items!.map(item => item.companyId);
  let selectedIds = Array.from(getValues("selectedIds") || [])
  
  const toggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (itemIds.length == selectedIds.length){
      selectedIds = []
      setValue("selectedIds", selectedIds)
      onChange(event)
    } else {
      selectedIds = itemIds
      setValue("selectedIds", selectedIds)
      onChange(event)
    }
  }

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newId = event.currentTarget.value;

    let selectedIds = Array.from(getValues("selectedIds") || [])
    if(!selectedIds.includes(newId)){
      selectedIds.push(newId);
    } else{
      selectedIds.splice(selectedIds.indexOf(newId), 1);  //deleting
    }

    setValue("selectedIds", selectedIds.length > 0 ? selectedIds : []);
    onChange(event)
  };

  const filteredItems = items!.filter(x => filter == '' || x.name?.toLowerCase().includes(filter.toLowerCase() ))

  return (
    <div ref={ref} className="" style={style}>
      <label className="block font-medium" htmlFor={id}>
        {label}
      </label>
      <input type="hidden"/>
      {items!.length > 0 && (
        <>
          <div className="my-2">
            <label className="block text-sm font-medium text-gray-700" htmlFor="43">
              {t('search')}
              <span> ({t('selected')}: </span>
              <span>{selectedIds?.filter(x => x.length > 0)?.length})</span>
            </label>
            <input type="search" value={filter} name="filterItems" onChange={e => setFilter(e.target.value)} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:number-sm border-gray-300 rounded-md"/>
          </div>
          <div ref={ref} className="block w-full pl-3 pr-4 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border" style={style}>
            <div className="mb-3">
              {items!.length > 0 &&
                <label className="flex w-full">
                  <span className="flex-1">{t('toggle_all_bot_users')}</span>
                  <input type="checkbox" checked={itemIds.length == selectedIds.length}
                         onChange={toggleAll}>
                  </input>
                </label>
              }
            </div>
            <div>
              {filteredItems!.map(edge => (
                <div key={edge!.id}>
                  <label className="flex w-full">
                    <span className="flex-1 inline-tags">
                      <span className="label">{edge!.name}</span>
                    </span>
                    <input type="checkbox" name={edge!.companyId} checked={selectedIds.includes(edge!.companyId)} onChange={(event)=> onChangeSelect(event)} key={edge!.companyId} value={edge!.companyId}>
                    </input>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <ErrorMessage errors={errors} className="mt-2 text-sm text-red-600" name={name} as="p" />
    </div>
  )
})
