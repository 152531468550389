import * as React from 'react'
import { DefaultValues, FormProvider, SubmitHandler, UseFormReturn, useFormContext } from 'react-hook-form'
import { useId } from '@reach/auto-id'
import cx from 'clsx'
import { FormField, FormFieldProps } from '../FormField'

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  id?: string;
  placeholder?: string;
  type?: string;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(({ className, ...props }, ref) => (
    <select ref={ref} className={cx('block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md', className)} {...props} />
  )
)

export interface SelectFieldProps extends Omit<FormFieldProps, 'labelFor'>, SelectProps {
  name: string
}

export const SelectField = React.forwardRef<HTMLSelectElement, SelectFieldProps>((props, ref) => {
  const id = useId(props.id);
  const { id: unusedId, className, style, label, ...rest } = props

  return (
    <FormField className={className} style={style} label={label} labelFor={id} name={props.name} errors={props.errors}>
      <Select ref={ref} id={id} {...rest} />
    </FormField>
  )
})
