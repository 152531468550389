import * as React from 'react'
import { useId } from '@reach/auto-id'
import { FormField, FormFieldProps } from '../FormField'
import {useTranslation} from "react-i18next";
import {bool} from "yup";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  placeholder?: string;
  type?: string;
  showHint: boolean;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => (
  <textarea ref={ref} {...props} />
))

export interface TextareaFieldProps extends Omit<FormFieldProps, 'labelFor'>, TextareaProps {
  name: string
}

export const TextareaField = React.forwardRef<HTMLTextAreaElement, TextareaFieldProps>((props, ref) => {
  const id = useId(props.id);
  const { id: unusedId, className, label, ...rest } = props
  const { t, i18n } = useTranslation();

  return (
      <div className="flex">
        <div className="grow">
          <FormField className={className} label={label} labelFor={id} name={props.name} errors={props.errors}>
            <Textarea ref={ref} id={id} className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" {...rest} />
          </FormField>
          </div>
        {props.showHint &&
          <div className="text-xs ml-2 mt-6 border border-gray-300 rounded-md p-2 text-gray-500" style={{flexBasis: '150px'}}>
            <p>{t('format_message')}</p>
            <ul>
              <li className="whitespace-nowrap">
                {t('italic')}<b>_text_</b>
              </li>
              <li className="whitespace-nowrap">
                {t('bold')}<b>*text*</b>
              </li>
              <li className="whitespace-nowrap">
                {t('strikethrough')}<b>~text~</b>
              </li>
            </ul>
          </div>
        }
      </div>
  )
})
