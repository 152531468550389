import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {useNavigate, useSearchParams, createSearchParams, useLocation} from 'react-router-dom';
import {SlideOver} from "../components/SlideOver";
import {UserForm, UserValues} from "../components/UserForm";
import {CompanyForm, CompanyValues} from "../components/CompanyForm";
import {useTranslation} from "react-i18next";

interface CompaniesCreateRouteProps {
}

const CompaniesCreateRoute: React.FC<CompaniesCreateRouteProps> = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const [createCompany] = useMutation(gql`
    mutation CompaniesCreateRoute_CreateCompanyMutation($input: CreatCompanyMutationInput!) {
      createCompany(input: $input) {
        company {
          id
        }
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'CompaniesOverviewRouteQuery',
    ]
  })

  function onCancel() {
    navigate(`/companies`)
  }

  async function onSubmit(values: CompanyValues) {
    let { data } = await createCompany({ variables: { input: { ...values } } })
    navigate({pathname: `/companies`}, {replace: true})
  }

  return (
    <SlideOver title={t('company')} onClose={onCancel}>
      <CompanyForm
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </SlideOver>
  )
}

export default CompaniesCreateRoute;
