import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { BotModulesUpdateRoute_UpdateBotModuleMutation, BotModulesUpdateRoute_UpdateBotModuleMutationVariables } from '../__generated__/BotModulesUpdateRoute_UpdateBotModuleMutation';
import { BotModulesUpdateRouteQuery } from '../__generated__/BotModulesUpdateRouteQuery';
import { BotModuleForm, BotModuleValues } from '../components/BotModuleForm';
import { SlideOver } from '../components/SlideOver';
import {useTranslation} from "react-i18next";

interface BotModulesUpdateRouteProps {
  companyId: string;
}

const BotModulesUpdateRoute: React.FC<BotModulesUpdateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { botModuleId } = useParams<'botModuleId'>();
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery<BotModulesUpdateRouteQuery>(gql`
    query BotModulesUpdateRouteQuery($botModuleId: ID!, $companyId: ID!) {
      botModule(id: $botModuleId) {
        id
        companyId

        ...BotModuleForm_botModule
      }

      company(id: $companyId) {
        folders {
          ...BotModuleForm_folders
        }

        themes {
          ...BotModuleForm_themes
        }
      }
    }

    ${BotModuleForm.fragments.botModule}
    ${BotModuleForm.fragments.folders}
    ${BotModuleForm.fragments.themes}
  `, {
    variables: {
      botModuleId: botModuleId as string,
      companyId
    }
  });

  const [updateBotModule] = useMutation<BotModulesUpdateRoute_UpdateBotModuleMutation, BotModulesUpdateRoute_UpdateBotModuleMutationVariables>(gql`
    mutation BotModulesUpdateRoute_UpdateBotModuleMutation($input: UpdateBotModuleMutationInput!) {
      updateBotModule(input: $input) {
        botModule {
          id
          folderId
        }
      }
    }
  `)

  function onCancel() {
    navigate(data!.botModule!.folderId
      ? `/${companyId}/bot_modules?folder=${data!.botModule!.folderId}`
      : `/${companyId}/bot_modules`
    )
  }

  async function onSubmit(values: BotModuleValues) {
    let { data } = await updateBotModule({ variables: { input: { id: botModuleId as string, ...values } } })

    navigate(data!.updateBotModule!.botModule.folderId
      ? `/${companyId}/bot_modules?folder=${data!.updateBotModule!.botModule.folderId}`
      : `/${companyId}/bot_modules`
    )
  }

  return (
    <SlideOver title={t('edit_bot_module')} onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (!data || !data.botModule || data.botModule.companyId !== companyId) ? (
        <p>{t('not_found')}</p>
      ) : (
        <BotModuleForm
          botModule={data.botModule}
          folders={data!.company!.folders}
          themes={data!.company!.themes}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </SlideOver>
  )
}

export default BotModulesUpdateRoute;
