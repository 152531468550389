import * as React from 'react'
import { DefaultValues, FormProvider, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form'
import { CardType } from '../../__generated__/globalTypes'
import { isEmptyChildren, isFunction } from '../../utils'
import { Button, Appearance, Intent } from '../Button'
import { TextInputField } from '../text-input-field'
import { CardValues } from '.'
import { sendCard } from '../../libraries/librequestutils';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImageFormSchema } from './image-form';
import { TextFormSchema } from './text-form';
import { QuizFormSchema } from './quiz-form';
import { OpenFormSchema } from './open-form';
import { VideoFormSchema } from './video-form';
import * as yup from "yup";
import {useTranslation} from "react-i18next";

function schemaType(cardType: string): object {
  switch(cardType) {
    case "IMAGE":
      return ImageFormSchema;
    case "TEXT":
      return TextFormSchema;
    case "VIDEO":
      return VideoFormSchema;
    case "QUIZ":
      return QuizFormSchema;
    case "OPEN":
      return OpenFormSchema;
    default:
      return {}
  }
}

function assembleSchema(cardType: string) {
  return yup.object({
    title: yup.string().required().label("Title"),
    ...schemaType(cardType)
  })
}

export interface BaseFormProps<TValues extends CardValues> {
  card: { id: string | null, botModuleId: string, cardType: CardType };
  children?: ((methods: UseFormReturn<TValues>) => React.ReactNode) | React.ReactNode | undefined;
  defaultValues: DefaultValues<TValues>
  onSubmit: SubmitHandler<TValues>
  onCancel: () => Promise<any> | any
  onDelete: () => Promise<any> | any
}

export const BaseForm = <TValues extends CardValues>({
  card,
  children,
  defaultValues,
  onSubmit,
  onCancel,
  onDelete
}: BaseFormProps<TValues>) => {
  let methods = useForm<TValues>({ defaultValues, resolver: yupResolver(assembleSchema(card.cardType)) })
  let [isCancelling, setIsCancelling] = React.useState<boolean>(false)
  let [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const { t, i18n } = useTranslation();
  async function onCancelClick() {
    setIsCancelling(true)
    await onCancel()
    setIsCancelling(false)
  }

  async function onDeleteClick() {
    setIsDeleting(true)
    await onDelete()
    setIsDeleting(false)
  }

  React.useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues])

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <div className="space-y-6">
          <TextInputField maxLength={20} label={t('title')} {...methods.register("title")} errors={methods.formState.errors} />

          {children
            ? isFunction(children)
              ? children(methods)
              : !isEmptyChildren(children)
              ? React.Children.only(children)
              : null
            : null}
        </div>

        <div className="pt-5">
          <div className="flex justify-end space-x-3">
            {card.id ? (
              <div>
                <Button appearance={Appearance.Outline} intent={Intent.Danger} type="button" disabled={methods.formState.isSubmitting || isCancelling || isDeleting} onClick={() => onDeleteClick()}>
                  {t('delete')}
                </Button>
              </div>
            ) : (
              <Button appearance={Appearance.Outline} type="button" onClick={() => onCancelClick()}>
                {t('cancel')}
              </Button>
            )}

            <Button type="submit" disabled={methods.formState.isSubmitting || isCancelling || isDeleting}>
              {t('save')}
            </Button>
          </div>
        </div>
      </FormProvider>
    </form>
  )
}
