import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CompaniesRoute from './CompaniesRoute';
import CompanyRoute from './CompanyRoute';
import CompaniesCreateRoute from "./CompaniesCreateRoute";

const RootRoute: React.VFC = () => {
  const location = useLocation();

  if (location.pathname === '/') {
    return <Navigate to="/companies" />;
  }

  return (
    <React.Fragment>
      <Routes>
        <Route path="/companies*" element={<CompaniesRoute />} />
        {/*<Route path="/companies/new" element={<CompaniesCreateRoute />} />*/}
        <Route path="/:companyId/*" element={<CompanyRoute />} />
      </Routes>
    </React.Fragment>
  )
}

export default RootRoute;
