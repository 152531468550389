import * as React from 'react'
import { FileInputField, FileInputFieldProps } from './FileInputField'
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

export const ALLOWED_FORMATS = ['image/jpeg', 'image/png']

export const imageTypeTest = {
	name: 'fileFormat',
	message: `image must be any of type: ${ALLOWED_FORMATS.join(", ")}`,
	test: (value: FileList|null|string) => {
		if (!value || typeof value === "string") return true;
		return value instanceof FileList && ALLOWED_FORMATS.includes(value?.[0]?.type)
	}
}

export const imageSizeTest = {
	name: 'fileSize',
	message: "image must be smaller than 5MB",
	test: (value: FileList|null|string) => {
		if (!value || typeof value === "string") return true;
		return value instanceof FileList && value?.[0]?.size <= 5242880
	}
}

export interface ImageInputFieldProps extends FileInputFieldProps {
	label: string
}

export const ImageInputField = React.forwardRef<ImageInputFieldProps>((props, ref) => {
  const { t, i18n } = useTranslation();
  return (
		<FileInputField
			ref={ref}
			label={t('image')}
			accept={ALLOWED_FORMATS.join(", ")}
			inputhint="PNG, JPG up to 5MB"
			{...props}
		/>
	)
})
