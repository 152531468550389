import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {UsersOverviewRouteQuery} from "../__generated__/UsersOverviewRouteQuery";
import {UsersOverviewRouteQueryVariables} from "../__generated__/UsersOverviewRouteQuery";
import {Button} from "../components/Button";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  UsersOverviewRoute_DeleteUserMutation,
  UsersOverviewRoute_DeleteUserMutationVariables
} from "../__generated__/UsersOverviewRoute_DeleteUserMutation";
import {
  UsersOverviewRoute_DetachUserMutation,
  UsersOverviewRoute_DetachUserMutationVariables
} from "../__generated__/UsersOverviewRoute_DetachUserMutation";
import {Menu, MenuButton, MenuItem, MenuLink, MenuList} from "@reach/menu-button";
import {DotsVerticalIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";

interface UsersOverviewRouteProps {
  companyId: string;
}
const UsersOverviewRoute: React.VFC<UsersOverviewRouteProps> = ({ companyId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)
  const { t, i18n } = useTranslation();

  const { data, loading, error, refetch } = useQuery<UsersOverviewRouteQuery, UsersOverviewRouteQueryVariables>(gql`
    query UsersOverviewRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        users {
          id
          name
          role
          username
          email
        }
      }
    }
  `, {
    variables: {
      companyId
    }
  })

  const [deleteBotUser] = useMutation<UsersOverviewRoute_DeleteUserMutation, UsersOverviewRoute_DeleteUserMutationVariables>(gql`
    mutation UsersOverviewRoute_DeleteUserMutation($input: DeleteUserMutationInput!) {
      deleteUser(input: $input) {
        deletedId
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'UsersOverviewRouteQuery'
    ]
  })

  const [detachBotUser] = useMutation<UsersOverviewRoute_DetachUserMutation, UsersOverviewRoute_DetachUserMutationVariables>(gql`
    mutation UsersOverviewRoute_DetachUserMutation($input: DetachUserMutationInput!) {
      detachUser(input: $input) {
        detachedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'UsersOverviewRouteQuery'
    ]
  })

  function onBotUserDelete(id: string) {
    deleteBotUser({ variables: { input: { id } } }).then(x =>{
      setMutationErrors(x.data?.deleteUser?.errors ? x.data.deleteUser.errors : [])
    })
  }

  function onBotUserDetach(id: string) {
    detachBotUser({ variables: { input: { companyId, id } } })
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            {t('users')}
          </h1>
          <div className="flex items-center space-x-3">
            <Button disabled={loading} onClick={() => navigate({
              pathname: 'new',
            }, { state: { backgroundLocation: location } })}>
              {t('create')}
            </Button>
            <Button disabled={loading} onClick={() => navigate({
              pathname: 'attach',
            }, { state: { backgroundLocation: location } })}>
              {t('attach')}
            </Button>
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (
          <div className="overflow-auto border border-gray-200 rounded-lg relative">
            <table className="min-w-full">
              <thead className="bg-gray-50">
              <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                <th scope="col" className="pl-8 py-3">
                  {t('name')}
                </th>
                <th scope="col" className="px-8 py-3 text-right">
                  {t('role')}
                </th>
                <th scope="col" className="px-8 py-3 text-right">
                  {t('username')}
                </th>
                <th scope="col" className="px-8 py-3 text-left">
                  {t('email')}
                </th>
                <th scope="col" className="pr-8 py-3 text-right">
                  <span className="sr-only">{t('actions')}</span>
                </th>
              </tr>
              </thead>
              <tfoot className="bg-white border-t border-gray-200">
              <tr>
                <td className="px-8 py-3" colSpan={5}>
                </td>
              </tr>
              </tfoot>
              <tbody className="bg-white divide-y divide-gray-100">
              <>
                {data?.company?.users && [...data!.company!.users!]
                  .map(user =>
                    <tr key={user.id}>
                      <td className="px-8 py-3 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900">{user.name}</td>
                      <td>{user.role}</td>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td className="px-8 py-3 text-sm text-gray-500 font-medium space-x-3">
                        <Menu>
                          <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                            <span className="sr-only">{t('options')}</span>
                            <DotsVerticalIcon className="h-5 w-5" />
                          </MenuButton>
                          <MenuList className="w-40">
                            {/*<MenuLink as={Link} to={botUser.id}>*/}
                            {/*  Details*/}
                            {/*</MenuLink>*/}
                            <MenuItem onSelect={() => onBotUserDetach(user.id)}>
                              {t('detach')}
                            </MenuItem>
                            <MenuItem className="text-red-600" onSelect={() => onBotUserDelete(user.id)}>
                              {t('delete')}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </td>
                    </tr>
                  )}
              </>
              </tbody>
            </table>
          </div>
        )}
      </main>

      {mutationErrors && mutationErrors.length > 0 &&
        <div className="fixed right-10 bottom-10 px-5 py-4 border-r-8 bg-red-500 bg-white drop-shadow-lg">
          <p className="text-sm text-white">
            <span className="mr-2 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
            {mutationErrors.join(', ')}
          </p>
        </div>
      }
    </div>
  );
}

export default UsersOverviewRoute;
