import * as React from 'react'
import { useId } from '@reach/auto-id'
import cx from 'clsx'
import { FormField, FormFieldProps } from '../FormField'

export interface BooleanInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const BooleanInput = React.forwardRef<HTMLInputElement, BooleanInputProps>(({ className, disabled, type = 'checkbox', ...props }, ref) => {
  return (
    <input
      ref={ref}
      type={type}
      disabled={disabled}
      className={cx(
        'ml-2 -mt-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"',
        { 'bg-gray-100 text-gray-400': disabled === true },
        className
      )}
      {...props}
    />
  )
})

export interface BooleanInputFieldProps extends Omit<FormFieldProps, 'labelFor'>, BooleanInputProps {
  name: string
}

export const BooleanInputField = React.forwardRef<HTMLInputElement, BooleanInputFieldProps>((props, ref) => {
  const id = useId(props.id);
  const { id: unusedId, className, label, ...rest } = props
  const booleanClassName = className || "" + " flex content-center items-center"
  return (
    <FormField className={booleanClassName} label={label} labelFor={id} name={props.name} errors={props.errors}>
      <BooleanInput ref={ref} id={id} {...rest}/>
    </FormField>
  )
})
