import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import LocalTime from '../components/LocalTime'
import { WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableQuery } from '../__generated__/WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableQuery';
import { WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableRow_whatsappBroadcast } from '../__generated__/WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableRow_whatsappBroadcast';
import { Button } from '../components/Button';
import useHasChanged from '../hooks/has-changed';
import { VFCwF } from '../types';
import { getFromBackend } from '../libraries/librequestutils';
import Popover from '../components/Popover';
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { Menu, MenuList, MenuButton, MenuItem, MenuLink } from "@reach/menu-button";
import {useTranslation} from "react-i18next";

interface WhatsappBroadcastTableRowProps {
  whatsappBroadcast: WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableRow_whatsappBroadcast,
  onDelete?: (id: string) => void
}

const WhatsappBroadcastTableRow: VFCwF<WhatsappBroadcastTableRowProps> = ({ whatsappBroadcast, onDelete }) => {
  const { t, i18n } = useTranslation();
  return (
    <tr className="text-sm text-gray-500 font-medium">
      <td className="px-8 py-3 whitespace-nowrap text-left text-gray-900">
        {whatsappBroadcast.scheduledAt && <LocalTime
          dateTime={whatsappBroadcast.scheduledAt}
          format="{YYYY}-{MM}-{DD} {HH}:{mm}"
          />}
      </td>
      <td className="px-8 py-3 whitespace-nowrap text-left text-gray-900">
        {whatsappBroadcast.deliveredAt && <LocalTime
          dateTime={whatsappBroadcast.deliveredAt}
          format="{YYYY}-{MM}-{DD} {HH}:{mm}"
          />}
      </td>
      <td className="px-8 py-3">
        {whatsappBroadcast.whatsappBroadcastTemplate?.name}
      </td>
      <td className="px-8 py-3">
        <span className="whitespace-nowrap">
          {whatsappBroadcast.departments
            .map((department) => department.name)
            .join(', ')}
        </span>
      </td>
      <td className="px-8 py-3">
        {whatsappBroadcast.botUsersCount}
      </td>
      <td className="px-8 py-3">
        {whatsappBroadcast.botUsersSuccessCount}
      </td>
      <td className="px-8 py-3">
        {whatsappBroadcast.botUsersErrors}
      </td>
      <td className="px-8 py-3 text-sm text-gray-500 font-medium space-x-3">
        {(whatsappBroadcast.scheduledAt && !whatsappBroadcast.deliveredAt) && (<Menu>
          <MenuButton className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            <span className="sr-only">{t('options')}</span>
            <DotsVerticalIcon className="h-5 w-5" />
          </MenuButton>
          <MenuList className="w-40">
            <MenuItem onSelect={() => onDelete && onDelete(whatsappBroadcast.id)}>
              {t('delete')}
            </MenuItem>
          </MenuList>
        </Menu>)}
      </td>
    </tr>
  )
}

WhatsappBroadcastTableRow.fragments = {
  whatsappBroadcast: gql`
    fragment WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableRow_whatsappBroadcast on WhatsappBroadcast {
      id
      botUsersCount
      botUsersSuccessCount
      botUsersErrors
      scheduledAt
      deliveredAt

      departments {
        name
      }

      whatsappBroadcastTemplate {
        name
      }
    }
  `
}

interface WhatsappBroadcastTableProps {
  companyId: string
  userRole: string
}

const WhatsappBroadcastTable: React.VFC<WhatsappBroadcastTableProps> = ({ userRole, companyId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);

  const [deleteWhatsappBroadcast] = useMutation(gql`
    mutation WhatsappBroadcastOverviewRoute_DeleteWhatsappBroadcastMutation($input: DeleteWhatsappBroadcastMutationInput!) {
      deleteWhatsappBroadcast(input: $input) {
        deletedId
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableQuery']
  })

  const { data, loading, error, refetch } = useQuery<WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableQuery>(gql`
    query WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableQuery($companyId: ID!, $after: String, $before: String) {
      company(id: $companyId) {
        id

        whatsappBroadcasts(first: 10, after: $after, before: $before) {
          edges {
            node {
              id
              ...WhatsappBroadcastsOverviewRoute_WhatsappBroadcastTableRow_whatsappBroadcast
            }
          }

          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }

    ${WhatsappBroadcastTableRow.fragments.whatsappBroadcast}
  `, {
    variables: {
      companyId,
      after: searchParams.get('after'),
      before: searchParams.get('before')
    }
  });


  function onWhatsappBroadcastDelete(id: string) {
    deleteWhatsappBroadcast({ variables: { input: { id } } })
  }

  React.useEffect(() => {
    if (hasStateChanged && !state?.backgroundLocation) {
      // Refetch themes when the modal is closed by a navigate action.
      refetch()
    }
  })

  return (
    <div className="overflow-auto border border-gray-200 rounded-lg relative">
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
            <th scope="col" className="pl-8 py-3">
              {t('scheduled_at')}
            </th>
            <th scope="col" className="px-8 py-3">
              {t('delivered_at')}
            </th>
            <th scope="col" className="px-8 py-3">
              {t('template')}
            </th>
            <th scope="col" className="px-8 py-3" >
              {t('departments')}
            </th>
            <th scope="col" className="pr-8 py-3" >
              {t('bot_users_scheduled')}
            </th>
            <th scope="col" className="pr-8 py-3" >
              {t('bot_users_send')}
            </th>
            <th scope="col" className="pr-8 py-3" >
              {t('errors')}
            </th>
            <th scope="col" className="pr-8 py-3 text-right" >
              <span className="sr-only">{t('actions')}</span>
            </th>
          </tr>
        </thead>
        <tfoot className="bg-white border-t border-gray-200">
          <tr>
            <td className="px-8 py-3" colSpan={8}>
              <div className="flex justify-end">
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    onClick={() => setSearchParams({ before: data.company.whatsappBroadcasts.pageInfo.startCursor})}
                    disabled={!data?.company.whatsappBroadcasts.pageInfo.hasPreviousPage}
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" />
                  </button>

                  <button
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    onClick={() => setSearchParams({ after: data.company.whatsappBroadcasts.pageInfo.endCursor})}
                    disabled={!data?.company.whatsappBroadcasts.pageInfo.hasNextPage}
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </td>
          </tr>
        </tfoot>
        <tbody className="bg-white divide-y divide-gray-100">
          {loading ? (
            <tr>
              <td className="px-8 py-3 text-sm text-gray-500" colSpan={8}>
                <div className="flex items-center justify-center h-96">
                  {t('loading')}
                </div>
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td className="px-8 py-3 text-sm text-gray-500" colSpan={8}>
                {t('error')}
              </td>
            </tr>
          ) : data?.company.whatsappBroadcasts.edges.length ? (
            data?.company.whatsappBroadcasts.edges.map(edge => (
              <WhatsappBroadcastTableRow key={edge.node.id} whatsappBroadcast={edge.node} onDelete={(edge.node.scheduledAt && !edge.node.deliveredAt) && onWhatsappBroadcastDelete}/>
            ))
          ) : (
            <tr>
              <td className="px-8 py-3 text-sm text-gray-500" colSpan={5}>
                {t('not_found')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

interface WhatsappBroadcastsOverviewRouteProps {
  companyId: string
  userRole: string
}

const WhatsappBroadcastsOverviewRoute: React.VFC<WhatsappBroadcastsOverviewRouteProps> = ({ userRole, companyId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [syncing, setSyncing] = React.useState(false)
  const [syncSuccess, setSyncSuccess] = React.useState("")
  const [syncErrors, setSyncErrors] = React.useState("")
  const { t, i18n } = useTranslation();
  async function syncTemplates() {
    setSyncing(true)

    const result = await getFromBackend('/whatsapp/sync_templates?company_id=' + companyId)

    const json = await result.json()

    if (result.status === 200) {
      navigate(`/${companyId}/whatsapp_broadcasts`, { state: { backgroundLocation: location }, replace: true })
      setSyncSuccess(json)
    }
    if (result.status !== 200) {
      setSyncErrors(json)
    }
    setSyncing(false)
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between h-10 spaxe-x-5">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            {t('broadcasts')}
          </h1>
          <div className="flex items-center space-x-3">
            {syncErrors &&
              <Popover input={syncErrors} windowClass="text-red-500 z-50" >
                <ExclamationCircleIcon className="flex ml-auto w-8 h-8 text-red-500 hover:text-red-200 hover:cursor-pointer" />
              </Popover>}
            {syncSuccess &&
              <Popover input={syncSuccess} windowClass="text-green-500 z-50" >
                <CheckCircleIcon className="flex ml-auto w-8 h-8 text-green-500 hover:text-gree-200 hover:cursor-pointer" />
              </Popover>}
            {(userRole == 'admin' || userRole == 'superadmin') &&
              <>
                <Button disabled={syncing} onClick={syncTemplates} >
                  {syncing ? "Syncing..." : t('sync_templates')}
                </Button>
                <Button onClick={() => navigate('send', { state: { backgroundLocation: location }, replace: true })}>
                  {t('send_broadcast')}
                </Button>
              </>
            }
          </div>
        </div>
      </header>

      <main className="mt-8">
        <WhatsappBroadcastTable userRole={userRole} companyId={companyId} />
      </main>
    </div>
  )
}

export default WhatsappBroadcastsOverviewRoute;
