import * as React from 'react';
import {Controller, DefaultValues, SubmitHandler, useForm} from 'react-hook-form'
import { gql } from '@apollo/client';
import { BotModuleForm_botModule } from '../__generated__/BotModuleForm_botModule';
import { BotModuleForm_folders } from '../__generated__/BotModuleForm_folders';
import { BotModuleForm_themes } from '../__generated__/BotModuleForm_themes';
import { Button, Appearance } from './Button';
import { SelectField } from './select-field';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

const BotModuleSchema = yup.object({
  name: yup.string().required().label("Name"),
  folderId: yup.string().nullable().label("Folder"),
  themeId: yup.string().nullable().label("Theme"),
  language: yup.string().oneOf(window.locales.split(',')).nullable().label("Language")
})

export interface BotModuleValues {
  name: string;
  folderId: string | null;
  themeId: string | null;
  language: string;
}

export interface BotModuleFormProps {
  botModule?: BotModuleForm_botModule | { name?: string, folderId?: string, themeId?: string, startDate?: Date, endDate?: Date }
  folders: BotModuleForm_folders[]
  themes: BotModuleForm_themes[]
  onCancel: () => void;
  onSubmit: SubmitHandler<BotModuleValues>;
}

export const BotModuleForm: VFCwF<BotModuleFormProps> = ({ botModule, folders, themes, onCancel, onSubmit }) => {
  const defaultValues: DefaultValues<BotModuleValues> = {
    name: botModule?.name,
    language: botModule?.language || 'nl',
    folderId: botModule?.folderId,
    themeId: botModule?.themeId
  };
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, formState } = useForm<BotModuleValues>({ defaultValues, resolver: yupResolver(BotModuleSchema) })

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <TextInputField label={t('name')} {...register('name')} errors={formState.errors}/>
        <SelectField label={t('language')} {...register('language')} errors={formState.errors} >
          {window.locales.split(',').map(x => (
            <option key={x} value={x}>{t('locale_' + x)}</option>
          ))}
        </SelectField>

        <SelectField label={t('folder')} {...register('folderId')} errors={formState.errors} >
          <option className="text-gray-500" value="">
            {t('no_folder')}
          </option>

          {folders.map(folder => (
            <option key={folder.id} value={folder.id}>
              {folder.name}
            </option>
          ))}
        </SelectField>
      </div>

      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

BotModuleForm.fragments = {
  botModule: gql`
    fragment BotModuleForm_botModule on BotModule {
      name
      language
      folderId
      themeId
    }
  `,
  folders: gql`
    fragment BotModuleForm_folders on Folder {
      id
      name
    }
  `,
  themes: gql`
    fragment BotModuleForm_themes on Theme {
      id
      name
      parentId
    }
  `
}
