import * as React from 'react';
import { Controller, SubmitHandler, useForm, useFormContext } from 'react-hook-form'
import { gql, useLazyQuery } from '@apollo/client';
import { WhatsappBroadcastForm_departments } from '../__generated__/WhatsappBroadcastForm_departments';
import { WhatsappBroadcastForm_whatsappBroadcastTemplates } from '../__generated__/WhatsappBroadcastForm_whatsappBroadcastTemplates';
import { WhatsappBroadcastFormQuery } from '../__generated__/WhatsappBroadcastFormQuery';
import { Button, Appearance } from './Button';
import { DatePickerField } from './DatePickerField';
import { SelectField } from './select-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {DepartmentCheckboxList} from "./whatsapp-broadcast-fields/department-input";
import {BotUsersCheckboxList} from "./whatsapp-broadcast-fields/bot-user-input";
import {useTranslation} from "react-i18next";

const WhatsappSchema = yup.object({
  departmentIds: yup.array().of(
    yup.string().required()
  ).required().min(1).label("Department"),
  botUserIds: yup.array().of(
    yup.string().required()
  ).required().min(1).label("Bot Users"),
  whatsappBroadcastTemplateId: yup.string().required().label("Template"),
  scheduledAt: yup.date().label("Scheduled At")
})

export interface WhatsappBroadcastValues {
  departmentIds: string[]
  botUserIds: string[]
  whatsappBroadcastTemplateId: string
  scheduledAt: Date | null
}

export interface WhatsappBroadcastFormProps {
  companyId: string
  departments: WhatsappBroadcastForm_departments[]
  whatsappBroadcastTemplates: WhatsappBroadcastForm_whatsappBroadcastTemplates[]
  onCancel: () => void;
  onSubmit: SubmitHandler<WhatsappBroadcastValues>;
}

export const WhatsappBroadcastForm: VFCwF<WhatsappBroadcastFormProps> = ({ companyId, departments, whatsappBroadcastTemplates, onCancel, onSubmit }) => {
  const { control, register, handleSubmit, formState, watch, setValue, getValues } = useForm<WhatsappBroadcastValues>({ defaultValues: { departmentIds: [] }, resolver: yupResolver(WhatsappSchema) })
  const departmentIds = watch("departmentIds");
  const { t, i18n } = useTranslation();

  let [selectedTemplate, setSelectedTemplate] = React.useState<WhatsappBroadcastForm_whatsappBroadcastTemplates>(whatsappBroadcastTemplates[0])

  // need to watch, otherwise views wont update
  const selectedBotUserIds = watch("botUserIds");

  const [getBotUsers, { data, loading, error }] = useLazyQuery<WhatsappBroadcastFormQuery>(gql`
    query WhatsappBroadcastFormQuery($companyId: ID!, $departmentIds: [ID!]!) {
      company(id: $companyId) {
        id

        botUsers(departmentIds: $departmentIds) {
          edges {
            node {
              id
              firstName
              lastName
              botUserType
            }
          }
        }
      }
    }
  `, {
    fetchPolicy: 'network-only',
  })
  //always get fresh data (fetchPolicy: 'network-only'

  function filterPassedTime(time: Date) {
    return (new Date()).getTime() < (new Date(time)).getTime();
  }

  function showPreview(event: React.ChangeEvent){
    const element = event.currentTarget as HTMLSelectElement;
    const value = element.options[element.selectedIndex].value;
    const template = whatsappBroadcastTemplates.find(x => x.id == value);
    if (template != null) {
      setSelectedTemplate(template);
    }
  }

  React.useEffect(() => {
    if (departmentIds.length > 0) {
      getBotUsers({ variables: { companyId, departmentIds } })
    } else {
      const departmentsQuery = [''] //otherwise we get them all
      getBotUsers({ variables: { companyId, departmentsQuery } })
    }
  }, [departmentIds])

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <DepartmentCheckboxList label={t('departments')} departments={departments} getValues={getValues} setValue={setValue} {...register('departmentIds')} errors={formState.errors}>
        </DepartmentCheckboxList>

        <BotUsersCheckboxList label={t('bot_users')} whatsappBotUsers={data?.company?.botUsers} getValues={getValues} setValue={setValue} {...register('botUserIds')} errors={formState.errors}>
        </BotUsersCheckboxList>

        <SelectField label={t('whatsapp_broadcast_template')} {...register('whatsappBroadcastTemplateId')} onChange={event => showPreview(event)} errors={formState.errors}>
          {whatsappBroadcastTemplates.map(whatsappBroadcastTemplate => (
            <option key={whatsappBroadcastTemplate.id} value={whatsappBroadcastTemplate.id}>
              {whatsappBroadcastTemplate.displayName} ({whatsappBroadcastTemplate.language})
            </option>
          ))}
        </SelectField>

        {selectedTemplate && (
          <div className="whatsapp-message">
            <div>
              <h2>{selectedTemplate.header}</h2>
              <div dangerouslySetInnerHTML={{__html: selectedTemplate.body || ''}}></div>
            </div>
          </div>
        )}

        <Controller
          name="scheduledAt"
          control={control}
          render={({ field }) => (
            <React.Fragment>
              <DatePickerField
                label={t('scheduled_at')}
                selected={field.value}
                minDate={new Date()}
                dateFormat="d MMMM yyyy HH:mm"
                timeFormat="HH:mm"
                timeIntervals={10}
                filterTime={filterPassedTime}
                onChange={field.onChange}
                showTimeSelect
                name={'scheduledAt'}
                errors={formState.errors}
              />
            </React.Fragment>
          )}
        />
      </div>

      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('send')}
          </Button>
        </div>
      </div>
    </form>
  )
}

WhatsappBroadcastForm.fragments = {
  departments: gql`
    fragment WhatsappBroadcastForm_departments on Department {
      id
      name
      kind
      language
    }
  `,
  whatsappBroadcastTemplates: gql`
    fragment WhatsappBroadcastForm_whatsappBroadcastTemplates on WhatsappBroadcastTemplate {
      id
      name
      displayName
      body
      header
      language
    }
  `
}

export default WhatsappBroadcastForm
