import * as React from 'react';
import {DefaultValues, SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import { gql } from '@apollo/client';
import { Button, Appearance } from './Button';
import { SelectField } from './select-field';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {TagSelect} from "./TagSelect";
import {useTranslation} from "react-i18next";

const QaModuleSchema = yup.object({
  name: yup.string().required().label("Name"),
})

export interface QaModuleValues {
  name: string;
  tags: string | null
}

export interface QaModuleFormProps {
  qaModule?: QaModuleValues
  onCancel: () => void;
  onSubmit: SubmitHandler<QaModuleValues>;
}

export const QaModuleForm: React.FunctionComponent<QaModuleFormProps> = ({ qaModule, onCancel, onSubmit }) => {
  const defaultValues: DefaultValues<QaModuleValues> = {
    name: qaModule?.name,
    tags: qaModule?.tags
  };
  const { t, i18n } = useTranslation();

  const { register, handleSubmit, formState, control, setValue, getValues } = useForm<QaModuleValues>({ defaultValues, resolver: yupResolver(QaModuleSchema) })

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <TextInputField label={t('name')} {...register('name')} errors={formState.errors}/>
      </div>
      <div className="space-y-6">
        <label className="mt-4 block text-sm font-medium text-gray-700">
          {t('q_a_search_tags')}
        </label>
        {/*<TextInputField label="Tags" {...register('tags')} errors={formState.errors}/>*/}
        <TagSelect label="Tags" tags={qaModule?.tags} getValues={getValues} setValue={setValue} {...register('tags')} errors={formState.errors}>
        </TagSelect>
      </div>
      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}
