import * as React from 'react'
import { TagsInput } from "react-tag-input-component";
import { useFormState, FieldErrors } from 'react-hook-form';
import {DepartmentCheckboxListProps} from "./whatsapp-broadcast-fields/department-input";
import {useId} from "@reach/auto-id";
import {useState} from "react";
import {useTranslation} from "react-i18next";

export interface TagListProps {
  name: string
  setValue: any
  getValues: any
  id?: string
  className?: string
  label?: string
  errors?: any
  children: any
  style?: any
  tags?: string | null
}

export const TagSelect = React.forwardRef<HTMLInputElement, TagListProps>((props, ref) => {
  const { id: unusedId, setValue, getValues, className, style, label, name, errors, tags, onChange } = props
  const id = useId(props.id);
  const { t, i18n } = useTranslation();

  const selectedTags = getValues("tags")
  const selected = (selectedTags == '' || !selectedTags) ? [] : selectedTags.split(';')

  const handleSelected = (tags: string[]) => {
    setValue("tags", tags.join(';'))
    onChange(event)
  }

  return (
    <TagsInput
      value={selected}
      onChange={handleSelected}
      placeHolder={t('short_description_enter_on_save')}
    />
  )
})
