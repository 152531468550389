import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { BotModulesUpdateRoute_UpdateBotModuleMutation, BotModulesUpdateRoute_UpdateBotModuleMutationVariables } from '../__generated__/BotModulesUpdateRoute_UpdateBotModuleMutation';
import { BotModulesUpdateRouteQuery } from '../__generated__/BotModulesUpdateRouteQuery';
import { BotModuleForm, BotModuleValues } from '../components/BotModuleForm';
import { SlideOver } from '../components/SlideOver';
import { QaModuleForm, QaModuleValues } from '../components/QaModuleForm';
import {QaModulesUpdateRouteQuery} from "../__generated__/QaModulesUpdateRouteQuery";
import {
  QaModulesUpdateRoute_UpdateQaModuleMutation, QaModulesUpdateRoute_UpdateQaModuleMutationVariables
} from "../__generated__/QaModulesUpdateRoute_UpdateQaModuleMutation";
import {useTranslation} from "react-i18next";

interface QaModulesUpdateRouteProps {
  companyId: string;
}

const QaModulesUpdateRoute: React.FC<QaModulesUpdateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { qaModuleId } = useParams<'qaModuleId'>();
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery<QaModulesUpdateRouteQuery>(gql`
    query QaModulesUpdateRouteQuery($qaModuleId: ID!) {
      qaModule(id: $qaModuleId) {
        id
        companyId
        name
        tagList
      }
    }
  `, {
    variables: {
      qaModuleId: qaModuleId as string,
      companyId
    }
  });

  const [updateQaModule] = useMutation<QaModulesUpdateRoute_UpdateQaModuleMutation, QaModulesUpdateRoute_UpdateQaModuleMutationVariables>(gql`
    mutation QaModulesUpdateRoute_UpdateQaModuleMutation($input: UpdateQaModuleMutationInput!) {
      updateQaModule(input: $input) {
        qaModule {
          id
        }
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/qa_modules`)
  }

  async function onSubmit(values: QaModuleValues) {
    let { data } = await updateQaModule({ variables: { input: { id: qaModuleId as string, ...values } } })

    navigate(`/${companyId}/qa_modules`)
  }

  return (
    <SlideOver title={t('edit_qa_module')} onClose={onCancel}>
      {loading ?(
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      )  : (!data || !data.qaModule || data.qaModule.companyId !== companyId) ? (
        <p>{t('not_found')}</p>
      ): (
        <QaModuleForm
          qaModule={{ name: data!.qaModule!.name, tags: data!.qaModule!.tagList || null }}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </SlideOver>
  )
}

export default QaModulesUpdateRoute;
