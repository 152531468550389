import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {useNavigate, useSearchParams, useLocation, useParams} from 'react-router-dom';
import {SlideOver} from "../components/SlideOver";
import {UserForm, UserValues} from "../components/UserForm";
import {CompanyForm, CompanyValues} from "../components/CompanyForm";
import {SubscriptionForm, SubscriptionValues} from "../components/SubscriptionForm";
import {useState} from "react";
import {QaModulesUpdateRouteQuery} from "../__generated__/QaModulesUpdateRouteQuery";
import {SubscriptionsUpdateRouteQuery} from "../__generated__/SubscriptionsUpdateRouteQuery";
import {useTranslation} from "react-i18next";

interface SubscriptionsUpdateRouteProps {
  companyId: string;
}

const SubscriptionsUpdateRoute: React.FC<SubscriptionsUpdateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const { subscriptionId } = useParams<'subscriptionId'>();
  const { t, i18n } = useTranslation();

  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)

  const { data, loading, error } = useQuery<SubscriptionsUpdateRouteQuery>(gql`
    query SubscriptionsUpdateRouteQuery($subscriptionId: ID!) {
      subscription(id: $subscriptionId) {
        id
        name
        freeSessions
        pricePerSession
        automaticRenewal
        expiresAt
        credits
        expirationMonths
        qaExpirationHours
        botSessionExpirationHours
        used
        fixedPrice
        companySubscriptions {
          companyId
        }
      }
    }
  `, {
    variables: {
      subscriptionId: subscriptionId as string,
      companyId
    }
  });

  const [updateSubscription] = useMutation(gql`
    mutation SubscriptionsUpdateRoute_UpdateSubscriptionMutation($input: UpdateSubscriptionMutationInput!) {
      updateSubscription(input: $input) {
        subscription {
          id
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'DashboardRouteQuery',
      'SubscriptionsUpdateRouteQuery'
    ]
  })

  function onCancel() {
    navigate(`/${companyId}`)
  }

  async function onSubmit(values: SubscriptionValues) {
    let { data } = await updateSubscription({ variables: { input: { id: subscriptionId,  ...values } } })
    setMutationErrors(data.updateSubscription.errors)

    if (data.updateSubscription.errors.length == 0) {
      navigate(`/${companyId}`)
    }
  }

  return (
    <SlideOver title={t('update_subscription')} onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (!data || !data.subscription) ? (
        <p>{t('not_found')}</p>
      ) : (
      <SubscriptionForm
        subscription={data!.subscription!}
        companyId={companyId}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />)}

      {mutationErrors && mutationErrors.length > 0 &&
        <div className="bottom-10 px-5 py-4 border-r-8 bg-red-500 bg-white drop-shadow-lg">
          <p className="text-sm text-white">
            <span className="mr-2 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
            {mutationErrors.join(', ')}
          </p>
        </div>
      }
    </SlideOver>
  )
}

export default SubscriptionsUpdateRoute;
