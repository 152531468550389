import * as React from 'react'
import { ErrorMessage } from '@hookform/error-message';
import { useFormState, FieldErrors } from 'react-hook-form';

export interface FormFieldProps {
  label: string;
  labelComponent?: React.ComponentType<{ className?: string, htmlFor?: string }>;
  labelFor?: string;
  name: string;
  errors?: FieldErrors;
}

export const FormField: React.FC<FormFieldProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  label,
  labelFor,
  errors,
  name,
  ...props
}) => {

  return (
    <div {...props}>
      {label &&
        <label className=" block text-sm font-medium text-gray-700" htmlFor={labelFor}>
          {label}
        </label>
      }

      <div className="mt-1">
        {children}
      </div>
      <ErrorMessage errors={errors} className="mt-2 text-sm text-red-600" name={name} as="p" />
    </div>
  )
}
