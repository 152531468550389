import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {useNavigate, useSearchParams, createSearchParams, useLocation} from 'react-router-dom';
import {SlideOver} from "../components/SlideOver";
import {UserForm, UserValues} from "../components/UserForm";
import {CompanyForm, CompanyValues} from "../components/CompanyForm";
import {SubscriptionForm, SubscriptionValues} from "../components/SubscriptionForm";
import {useState} from "react";
import {useTranslation} from "react-i18next";

interface SubscriptionsCreateRouteProps {
  companyId: string;
}

const SubscriptionsCreateRoute: React.FC<SubscriptionsCreateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)
  const { t, i18n } = useTranslation();

  const [createSubscription] = useMutation(gql`
    mutation SubscriptionsCreateRoute_CreateSubscriptionMutation($input: CreateSubscriptionMutationInput!) {
      createSubscription(input: $input) {
        subscription {
          id
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: [
      'DashboardRouteQuery',
    ]
  })

  function onCancel() {
    navigate(`/${companyId}`)
  }

  async function onSubmit(values: SubscriptionValues) {
    let { data } = await createSubscription({ variables: { input: {  ...values } } })
    setMutationErrors(data.createSubscription.errors)

    if (data.createSubscription.errors.length == 0) {
      navigate(`/${companyId}`)
    }
  }

  return (
    <SlideOver title={t('subscription')} onClose={onCancel}>
      <SubscriptionForm
        companyId={companyId}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
      {mutationErrors && mutationErrors.length > 0 &&
        <div className="bottom-10 px-5 py-4 border-r-8 bg-red-500 bg-white drop-shadow-lg">
          <p className="text-sm text-white">
            <span className="mr-2 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
            {mutationErrors.join(', ')}
          </p>
        </div>
      }
    </SlideOver>
  )
}

export default SubscriptionsCreateRoute;
