import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {useTranslation} from "react-i18next";
import { DepartmentLogsQuery } from '../__generated__/DepartmentLogsQuery';

interface DepartmentLogsProps {
  companyId: string;
  userRole: string;
}

const DepartmentLogs: React.VFC<DepartmentLogsProps> = ({ userRole, companyId }) => {
  const location = useLocation()

  const {departmentId} = useParams<'departmentId'>();
  
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const { data, loading, error, refetch } = useQuery<DepartmentLogsQuery>(gql`
    query DepartmentLogsQuery($departmentId: ID!) {
      department(id: $departmentId) { 
        id
        name 
        scheduledDepartmentLogs {
          id
          createdAt
          scheduledDepartmentBotUserLogs {
            fullName
            message
            data
          }
        }
      }
    }
  `, {
    variables: {
      departmentId: departmentId as string
    }
  });

  return (
    <div>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (!data || !data.department) ? (
        <p>{t('not_found')}</p>
      ) : (
        <div>
          <header>
            <div className="flex items-center justify-between space-x-5 h-10">
              <div className="flex items-center text-2xl font-semibold text-gray-300">
                <Link to={`/${companyId}/departments`} className="hover:text-gray-400">
                  {t('departments')}
                </Link>

                <svg className="flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>

                <span className="text-gray-900 truncate">
                  Logs
                </span>
              </div>
            </div>
          </header>
          <main className="mt-8">
            {data.department.scheduledDepartmentLogs.map(log => (    
              <div className="overflow-auto border border-gray-200 rounded-lg relative">
                <table className="min-w-full mb-4">
                  <thead className="bg-gray-50">
                    <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      <th scope="col" className="pl-8 py-3">{log.id}</th>
                      <th scope="col" className="px-8 py-3">{log.createdAt}</th>
                      <th scope="col" className="px-8 py-3">Data</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    <>
                    {log.scheduledDepartmentBotUserLogs.map(log => (
                      <tr className='px-8 py-3 text-sm text-left font-medium text-gray-500'>
                        <td className='px-8 py-3'>{log.fullName}</td>
                        <td className="px-8 py-3">{log.message}</td>
                        <td className="px-8 py-3 text-gray-400 text-xs">{log.data}</td>
                      </tr>
                      ))}
                    </>
                  </tbody>
                </table>
              </div>
            ))}
          </main>
        </div>
      )}
    </div>
  );
}

export default DepartmentLogs;
