import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { QuestionAnalyticsTableQuery, QuestionAnalyticsTableQuery_company_questions_edges_node } from '../__generated__/QuestionAnalyticsTableQuery'
import { VFCwF } from '../types';
import { Tab, TabProps } from '@reach/tabs';
import cx from "clsx"
import useHasChanged from '../hooks/has-changed';
import { Select } from '../components/select-field';
import LocalTime from '../components/LocalTime';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion'
import {useTranslation} from "react-i18next";

interface QuestionAnalyticsTableRowProps {
  question: QuestionAnalyticsTableQuery_company_questions_edges_node
  index: number
}

const QuestionAnalyticsTableRow: VFCwF<QuestionAnalyticsTableRowProps> = ({ question, index }) => {
  const { t, i18n } = useTranslation();
  const attempts = React.useMemo(() => {
    const regAttempts = question.questionAttempts;
    const surveyAttempts = question.surveyQuestionAttempts;
    return regAttempts!.length > 0 ? regAttempts : surveyAttempts
  }, [question])

  return (
    <AccordionItem as={({ children }) => <>{children}</>} >
      <AccordionButton as="tr" className="hover:cursor-pointer hover:bg-gray-50 text-gray-900">
        <td className="px-8 py-3 whitespace-nowrap text-sm text-left font-medium">
          {index + 1}
        </td>
        <td className="px-8 py-3 whitespace-nowrap text-sm text-left font-medium">
          <div className="whitespace-nowrap text-ellipsis overflow-hidden max-w-md">
            {question.botModule.name}
          </div>
        </td>
        <td className="px-8 py-3 whitespace-nowrap text-sm text-left font-medium">
          <div className="whitespace-nowrap text-ellipsis overflow-hidden max-w-md">
            {question.question}
          </div>
        </td>
        <td className="px-8 py-3 whitespace-nowrap text-sm text-left font-medium">
          {attempts?.length || 0}
        </td>
      </AccordionButton>
      <AccordionPanel as="tr">
        <td colSpan={5}>
          <table className="w-full border border-gray-200 bg-gray-50 table-fixed font-medium text-gray-500">
            <thead className="bg-gray-100">
              <tr>
                <th scope="col" className="w-2/12 pl-8 py-2 border-b border-gray-200 text-left text-xs uppercase tracking-wider whitespace-nowrap" >
                  {t('number')}
                </th>
                <th scope="col" className="w-3/12 px-8 py-2 border-b border-gray-200 text-left text-xs uppercase tracking-wider whitespace-nowrap" >
                  {t('date')}
                </th>
                <th scope="col" className="w-4/12 px-8 py-2 border-b border-gray-200 text-left text-xs uppercase tracking-wider whitespace-nowrap" >
                  {t('bot_user')}
                </th>
                <th scope="col" className="w-3/12 px-8 py-2 border-b border-gray-200 text-left text-xs uppercase tracking-wider whitespace-nowrap" >
                  {t('answer')}
                </th>
              </tr>
            </thead>
            <tbody>
              {attempts?.map((attempt, index) => (
                <tr key={index}>
                  <td className="px-8 py-3 text-sm">
                    {index + 1}
                  </td>
                  <td className="px-8 py-3 text-xs">
                    <LocalTime dateTime={attempt.createdAt} format="{YYYY}-{MM}-{DD} {HH}:{mm}" />
                  </td>
                  <td className="px-8 py-3 text-sm">
                    {attempt?.botUser?.fullName}
                  </td>
                  <td className="px-8 py-3 text-sm">
                    {attempt?.answerText}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </AccordionPanel>
    </AccordionItem>
  )
}

QuestionAnalyticsTableRow.fragments = {
  question: gql`
    fragment QuestionAnalyticsTableRow_question on Question {
      question
      questionAttempts {
        id
        createdAt
        answerText
        botUser {
          id
          fullName
        }
      }
      surveyQuestionAttempts {
        id
        createdAt
        answerText
        botUser {
          id
          fullName
        }
      }
      botModule {
        id
        name
      }
    }
  `
}

interface QuestionAnalyticsTableProps {
	companyId: string
  botModuleId?: string | null
  onBotModuleChange: (arg0: any, arg1: any) => void
  beforeCursor?: string | null
  afterCursor?: string | null
  handleCursorChange: Function
}

const QuestionAnalyticsTable: React.VFC<QuestionAnalyticsTableProps> = ({ companyId, onBotModuleChange, beforeCursor, afterCursor, handleCursorChange, botModuleId }) => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);
  const { t, i18n } = useTranslation();

  const { data, loading, error, refetch } = useQuery<QuestionAnalyticsTableQuery>(gql`
    query QuestionAnalyticsTableQuery($companyId: ID!, $after: String, $before: String, $botModuleIds: [ID!]) {
      company(id: $companyId) {
        id

        botModules {
          id
          name
        }

        questions(first: 10, after: $after, before: $before, botModuleIds: $botModuleIds) {
          edges {
            node {
              id
              ...QuestionAnalyticsTableRow_question
            }
          }

          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }

    ${QuestionAnalyticsTableRow.fragments.question}
  `, {
    variables: {
      companyId,
      after: afterCursor,
      before: beforeCursor,
      botModuleIds: botModuleId ? [botModuleId] : null
    }
  });

  React.useEffect(() => {
    if (hasStateChanged && !state?.backgroundLocation) {
      refetch()
    }
  })

  return (
    <>
      {!loading && (
        <div className="w-48 my-6 mr-auto flex space-x-5">
          <Select value={botModuleId || ""} onChange={(evt) => onBotModuleChange(evt, 'botModule')} >
            <option value="">{t('all_bot_modules')}</option>
            {data?.company?.botModules.map(module => (
              <option key={module.id} value={module.id} >
                {module.name}
              </option>
            ))}
          </Select>
        </div>
      )}
      <div className="overflow-auto border border-gray-200 rounded-lg relative">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="w-2/12 pl-8 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                {t('number')}
              </th>
              <th
                scope="col"
                className="w-3/12 px-8 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                {t('bot_module')}
              </th>
              <th
                scope="col"
                className="w-5/12 px-8 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                {t('questions')}
              </th>
              <th
                scope="col"
                className="w-2/12 pr-8 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                {t('number_of_replies')}
              </th>
            </tr>
          </thead>
          <tfoot className="bg-white border-t border-gray-200">
            <tr>
              <td className="px-8 py-3" colSpan={4}>
                <div className="flex justify-end">
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => handleCursorChange({ before: data?.company!.questions.pageInfo.startCursor})}
                      disabled={!data?.company!.questions.pageInfo.hasPreviousPage}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" />
                    </button>

                    <button
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => handleCursorChange({ after: data?.company!.questions.pageInfo.endCursor})}
                      disabled={!data?.company!.questions.pageInfo.hasNextPage}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon className="h-5 w-5" />
                    </button>
                  </nav>
                </div>
              </td>
            </tr>
          </tfoot>
          <Accordion as="tbody" className="bg-white divide-y divide-gray-100" collapsible multiple>
            {loading ? (
              <tr>
                <td className="px-8 py-3 text-sm text-gray-500" colSpan={5}>
                  <div className="flex items-center justify-center h-96">
                    {t('loading')}
                  </div>
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td className="px-8 py-3 text-sm text-gray-500" colSpan={5}>
                  {t('error')}
                </td>
              </tr>
            ) : data?.company!.questions?.edges?.length ? (
                data?.company.questions.edges.map((edge, index: number) => (
                  <QuestionAnalyticsTableRow key={edge.node.id} question={edge.node} index={index} />
                ))
                ) : (
              <tr>
                <td className="px-8 py-3 text-sm text-gray-500" colSpan={5}>
                  {t('not_found')}
                </td>
              </tr>
            )}
          </Accordion>
        </table>
      </div>
    </>
  );
}

export default QuestionAnalyticsTable;
