import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { WhatsappBroadcastsSendRoute_SendWhatsappBroadcastMutation, WhatsappBroadcastsSendRoute_SendWhatsappBroadcastMutationVariables } from '../__generated__/WhatsappBroadcastsSendRoute_SendWhatsappBroadcastMutation';
import { WhatsappBroadcastsSendRouteQuery } from '../__generated__/WhatsappBroadcastsSendRouteQuery';
import { SlideOver } from '../components/SlideOver';
import WhatsappBroadcastForm, { WhatsappBroadcastValues } from '../components/WhatsappBroadcastForm';
import useHasChanged from '../hooks/has-changed';
import {useTranslation} from "react-i18next";

interface WhatsappBroadcastsSendRouteProps {
  companyId: string;
}

const WhatsappBroadcastsSendRoute: React.VFC<WhatsappBroadcastsSendRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation();
  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = React.useState(initialMutationErrors)

  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);

  const { data, loading, error, refetch } = useQuery<WhatsappBroadcastsSendRouteQuery>(gql`
    query WhatsappBroadcastsSendRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id

        departments {
          ...WhatsappBroadcastForm_departments
        }

        whatsappBroadcastTemplates {
          ...WhatsappBroadcastForm_whatsappBroadcastTemplates
        }
      }
    }

    ${WhatsappBroadcastForm.fragments.departments}
    ${WhatsappBroadcastForm.fragments.whatsappBroadcastTemplates}
  `, {
    variables: {
      companyId
    }
  });

  const [sendWhatsappBroadcast] = useMutation<WhatsappBroadcastsSendRoute_SendWhatsappBroadcastMutation, WhatsappBroadcastsSendRoute_SendWhatsappBroadcastMutationVariables>(gql`
    mutation WhatsappBroadcastsSendRoute_SendWhatsappBroadcastMutation($input: SendWhatsappBroadcastMutationInput!) {
      sendWhatsappBroadcast(input: $input) {
        whatsappBroadcast {
          id
        }
        errors
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/whatsapp_broadcasts`)
  }

  async function onSubmit(values: WhatsappBroadcastValues) {
    let {data} = await sendWhatsappBroadcast({ variables: { input: { companyId, ...values } } })
    setMutationErrors(data?.sendWhatsappBroadcast?.errors || [])

    if (data?.sendWhatsappBroadcast?.errors.length == 0) {
      navigate(`/${companyId}/whatsapp_broadcasts`)
    }
  }

  React.useEffect(() => {
    if (hasStateChanged && state?.backgroundLocation) {
      refetch()
    }
  })

  return (
    <SlideOver title={t('sent_broadcasts')} onClose={onCancel}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (
        <WhatsappBroadcastForm
          companyId={companyId}
          departments={data!.company!.departments}
          whatsappBroadcastTemplates={data!.company!.whatsappBroadcastTemplates}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
      {mutationErrors && mutationErrors.length > 0 &&
        <div className="bottom-10 px-5 py-4 bg-red-500 bg-white drop-shadow-lg">
          <div className="text-sm text-white">
            <span className="mr-4 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
            {mutationErrors.join(', ')}
          </div>
        </div>
      }
    </SlideOver>
  )
}

export default WhatsappBroadcastsSendRoute;
