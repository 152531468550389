import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client';
import { CompanyChannelsForm_company } from '../__generated__/CompanyChannelsForm_company';
import { CompanyChannelsForm_UpdateCompanyMutation, CompanyChannelsForm_UpdateCompanyMutationVariables } from '../__generated__/CompanyChannelsForm_UpdateCompanyMutation';
import { Button } from './Button';
import { SelectField } from './select-field';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { CompanyChannelsForm_whatsappProviders } from '../__generated__/CompanyChannelsForm_whatsappProviders';
import { yupResolver } from '@hookform/resolvers/yup';
import {CompanyChannelsValues} from "./company-channels-form/company-channels-values";
import {CompanyChannelsSchema} from "./company-channels-form/company-channels-schema";
import {makeCompanyMutation} from "../queries/CompanyQueryHandlers";
import {Notification} from "./Notification";
import {useTranslation} from "react-i18next";
import { useState } from 'react';

export interface CompanyChannelsFormProps {
  company: CompanyChannelsForm_company
  whatsappProviders: CompanyChannelsForm_whatsappProviders[],
}

export const CompanyChannelsForm: VFCwF<CompanyChannelsFormProps> = ({ company, whatsappProviders }) => {
  const { t, i18n } = useTranslation();
  const defaultValues: DefaultValues<CompanyChannelsValues> = {
    whatsapp: {
      apiKey: company.whatsapp?.apiKey ?? '',
      code: company.whatsapp?.code ?? '',
      accountId: company.whatsapp?.accountId ?? '',
      mobile: company.whatsapp?.mobile ?? '',
      providerId: company.whatsapp?.providerId ?? '',
      authentication: company.whatsapp?.authentication ?? '',
    },
  }
  const { register, handleSubmit, formState } = useForm<CompanyChannelsValues>({ defaultValues, resolver: yupResolver(CompanyChannelsSchema) })

  const initialMutationErrors: string[] = [];
  const [mutationErrors, setMutationErrors] = useState(initialMutationErrors)

  const [updateCompany, {data, loading, error}] = makeCompanyMutation()

  const existingProvider = whatsappProviders.find(x => x.id == defaultValues.whatsapp?.providerId)

  let [selectedProviderId, setSelectedProviderId] = React.useState<string>(existingProvider?.name || '')

  function handleProviderChange(event: any) {
    const element = event.target as HTMLSelectElement;
    const value = element.options[element.selectedIndex].text;
    setSelectedProviderId(value)
  }

  const onSubmit: SubmitHandler<CompanyChannelsValues> = async (values) => {
    let { data } = await updateCompany({
      variables: {
        input: { id: company.id, ...values }
      }
    })
    if (data?.updateCompany?.errors){
      setMutationErrors(data?.updateCompany.errors)
    }

  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow rounded-md overflow-hidden">
        <div className="p-6 bg-white">
          <div className="space-y-6">
            <SelectField label={t('provider')} {...register('whatsapp.providerId')} errors={formState.errors} onChange={e => handleProviderChange(event)}>
              {whatsappProviders.map(provider => (
                <option key={provider.id} value={provider.id}>
                  {provider.name}
                </option>
              ))}
            </SelectField>
            <>
              <TextInputField label={t('whatsapp_api_key')} {...register('whatsapp.apiKey')} errors={formState.errors} />
              <TextInputField label={t('whatsapp_verification_code')} {...register('whatsapp.code')} errors={formState.errors} />
              <TextInputField label={t('whatsapp_account_id')} {...register('whatsapp.accountId')} errors={formState.errors} />
              <TextInputField label={t('whatsapp_mobile_number')} {...register('whatsapp.mobile')} errors={formState.errors} />
              {/* <TextInputField label="Web Bot URL" name="webBotUrl" value={`https://e-lia.bluerail.nl/webchat/${company.webBotKey}`} readOnly errors={formState.errors}/> */}
              <TextInputField label={t('web_bot_url')} name="webBotUrl" value={`https://gro-up.e-lia.eu/webchat`} readOnly errors={formState.errors}/>
            </>
            {selectedProviderId == 'whatsapp' ? (
              <>
                <TextInputField label={t('whatsapp_authentication')} {...register('whatsapp.authentication')} errors={formState.errors} />
              </>
              ) : (
              <>
                <TextInputField label={t('provider_webhook_url')} name="whatsapp.webhookUrl" value={`https://gro-up.e-lia.eu/whatsapp/${company.id}`} readOnly errors={formState.errors}/>
              </>
            )}
          </div>
          {data && <Notification text={t('updated')}></Notification>}
        </div>
        { mutationErrors && mutationErrors.length > 0 &&
          <div className="bottom-10 px-5 py-4 border-r-8 bg-red-500 bg-white drop-shadow-lg">
            <p className="text-sm text-white">
              <span className="mr-2 inline-block px-3 py-1 rounded-full bg-red-500 text-white drop-shadow-lg font-extrabold">i</span>
              {mutationErrors.join(', ')}
            </p>
          </div>
        }
        <div className="px-6 py-3 bg-gray-50 text-right">
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

CompanyChannelsForm.fragments = {
  company: gql`
    fragment CompanyChannelsForm_company on Company {
      id
      webBotKey

      whatsapp {
        accountId
        apiKey
        code
        mobile
        providerId
        authentication
      }
    }
  `,
  whatsappProviders: gql`
    fragment CompanyChannelsForm_whatsappProviders on WhatsappProvider {
      id
      name
    }
  `
}
