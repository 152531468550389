import * as React from 'react'

export default function useInnerRef<T>(
  forwardedRef: React.ForwardedRef<T>,
  initialValue: T | null
): React.RefObject<T> {
  const innerRef = React.useRef<T>(initialValue)

  React.useEffect(() => {
    if (forwardedRef) {
      if (typeof forwardedRef === 'function') {
        forwardedRef(innerRef.current)
      } else {
        forwardedRef.current = innerRef.current
      }
    }
  }, [forwardedRef, innerRef])

  return innerRef
}
