import React from 'react';
import {Link, useLocation, useRoutes} from 'react-router-dom';
import { gql, useQuery } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { CompaniesRoute_CompanyListItem_company } from '../__generated__/CompaniesRoute_CompanyListItem_company';
import { CompaniesRouteQuery } from '../__generated__/CompaniesRouteQuery'
import { Topbar } from '../components/Topbar';
import { VFCwF } from '../types'
import CompaniesCreateRoute from "./CompaniesCreateRoute";
import DashboardRoute from "./DashboardRoute";
import CompaniesOverviewRoute from "./CompaniesOverviewRoute";
import {useTranslation} from "react-i18next";

interface CompanyListItemProps {
  company: CompaniesRoute_CompanyListItem_company
}

interface RoutesProps {
}

interface ModalRoutesProps {
}

const Routes: React.VFC<RoutesProps> = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  const routes = useRoutes([
    { path: '/', element: <CompaniesOverviewRoute/>, index: true},
    { path: '/new', element: <CompaniesCreateRoute/>},
  ], state?.backgroundLocation || location)

  return routes
}

const ModalRoutes: React.VFC<ModalRoutesProps> = () => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  const routes = useRoutes([
    { path: '/new', element: <CompaniesCreateRoute />  },
  ])

  if (!state?.backgroundLocation) {
    return null
  }

  return routes
}


const CompaniesRoute: React.VFC = () => {
  const { t, i18n } = useTranslation();

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const onLanguageChange = (event) =>{
    const selectElement = event.currentTarget;
    const option = selectElement.options[selectElement.selectedIndex];
    document.cookie = "language=" + encodeURIComponent(option.value) + "; path=/";
    window.location.reload(true)
  }

  const { data, loading, error } = useQuery<CompaniesRouteQuery>(gql`
    query CompaniesRouteQuery {
      companies {
        id
      }

      user: viewer {
        ...Topbar_user
      }
    }
    ${Topbar.fragments.user}
  `);

  if (error) {
    return <p>{t('error')}</p>
  }

  return (
    <React.Fragment>
      <div className="bg-white shadow">
        <Topbar user={data?.user}
                menuLinks={
                  <div className="px-4 py-3">
                    <p className="text-xs text-gray-500">Taal</p>
                    <select className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" defaultValue={window.locale} name="languages" id="languages_switch" onChange={(evt) => onLanguageChange(evt)}>
                      <option value="nl">Nl</option>
                      <option value="en">EN</option>
                    </select>
                  </div>
                }
        />
      </div>

      <div className="py-10">
        {loading ? (
          <>{t('loading')}</>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (
          <>
            <ModalRoutes />
            <Routes/>
          </>
        )}

      </div>
    </React.Fragment>
  )
}

export default CompaniesRoute;
