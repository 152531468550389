import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { BotModulesCloneRouteQuery } from '../__generated__/BotModulesCloneRouteQuery';
import { BotModuleCloneForm } from '../components/BotModuleCloneForm';
import { SlideOver } from '../components/SlideOver';


import {BotModulesCloneRouteProps, loadBotModules} from "../queries/BotModulesQuerieHandler";
import {useTranslation} from "react-i18next";
// interface BotModulesCloneRouteProps {
//   companyId: string;
// }

const BotModulesCloneRoute: React.FC<BotModulesCloneRouteProps> = ({ companyId }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const { botModuleId } = useParams<'botModuleId'>();
  const { data, loading, error } = loadBotModules(botModuleId as string)

  function onClose() {
    navigate(data!.botModule!.folderId
      ? `/${companyId}/bot_modules?folder=${data!.botModule!.folderId}`
      : `/${companyId}/bot_modules`
    )
  }

  function onSubmitted(id: string) {
    navigate(`/${companyId}/bot_modules/${id}`)
  }

  return (
    <SlideOver title={t('clone_bot_module')} onClose={onClose}>
      {loading ? (
        <p>{t('loading')}</p>
      ) : error ? (
        <p>{t('error')}</p>
      ) : (!data || !data.botModule || data.botModule.companyId !== companyId) ? (
        <p>{t('not_found')}</p>
      ) : (
        <BotModuleCloneForm
          botModule={data.botModule}
          companies={data!.companies}
          translateOnly={false}
          onCancel={onClose}
          onSubmitted={onSubmitted}
        />
      )}
    </SlideOver>
  )
}

export default BotModulesCloneRoute;
