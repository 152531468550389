import * as React from 'react'
import {
  Combobox as ReachCombobox,
  ComboboxOption as ReachComboboxOption,
  ComboboxOptionProps as ReachComboboxOptionProps,
  ComboboxProps as ReachComboboxProps,
} from '@reach/combobox'

export { ComboboxInput, ComboboxList, ComboboxOptionText, ComboboxPopover } from '@reach/combobox'

const ComboboxContext = React.createContext<ComboboxContextValue>({} as any)

export const Combobox = React.forwardRef<HTMLDivElement, ComboboxProps>(({ onSelect: onSelectProp, ...props }, ref) => {
  const { addOptionData, getOptionData, removeOptionData } =
    useOptionDataFactory()

  const onSelectRef = React.useRef(onSelectProp)
  React.useEffect(() => {
    onSelectRef.current = onSelectProp
  })

  const onSelect = React.useCallback(
    function onSelect(value: string) {
      onSelectRef.current?.(value, getOptionData(value))
    },
    [getOptionData]
  )

  const context: ComboboxContextValue = React.useMemo(
    () => ({
      addOptionData,
      getOptionData,
      removeOptionData,
      onSelect,
    }),
    [onSelect, addOptionData, getOptionData, removeOptionData]
  )

  return (
    <ComboboxContext.Provider value={context}>
      <ReachCombobox {...props} ref={ref} as="div" onSelect={onSelect} />
    </ComboboxContext.Provider>
  )
})

export function ComboboxOption({ selectData, ...props }: ComboboxOptionProps) {
  const { addOptionData, removeOptionData } = React.useContext(ComboboxContext)
  React.useEffect(() => {
    addOptionData(props.value, selectData)
    return () => removeOptionData(props.value)
  }, [props.value, selectData, addOptionData, removeOptionData])

  return <ReachComboboxOption {...props} as="li" />
}

interface ComboboxDOMProps
  extends Omit<
    React.ComponentPropsWithoutRef<'div'>,
    keyof ReachComboboxProps
  > {}

interface ComboboxOptionDOMProps
  extends Omit<
    React.ComponentPropsWithoutRef<'li'>,
    keyof ReachComboboxOptionProps
  > {}

export interface ComboboxProps extends ReachComboboxProps, ComboboxDOMProps {
  onSelect?(value: string, data?: any): void
}

export interface ComboboxOptionProps
  extends ReachComboboxOptionProps,
    ComboboxOptionDOMProps {
  /**
   * Custom data that will be passed to the `onSelect` of the `Combobox` as a
   * second argument.
   */
  selectData?: any
}

/**
 * Uses a ref object which stores the index as a key and custom data as value
 * for each ComboboxOption. Hides the ref so that we can only mutate it through
 * the returned functions. 🙈
 */
function useOptionDataFactory(): {
  addOptionData: AddOptionData
  getOptionData: GetOptionData
  removeOptionData: RemoveOptionData
} {
  const optionData = React.useRef<OptionData>({})

  const addOptionData = React.useCallback<AddOptionData>(
    (value: string, data: any) => (optionData.current[value] = data),
    []
  )

  const getOptionData = React.useCallback<GetOptionData>(
    (value: string) => optionData.current[value],
    []
  )

  const removeOptionData = React.useCallback<RemoveOptionData>(
    (value: string) => delete optionData.current[value],
    []
  )

  return {
    addOptionData,
    getOptionData,
    removeOptionData,
  }
}

type OptionData = Record<string, any>

type AddOptionData = (value: string, data: any) => void

type GetOptionData = (value: string) => any | undefined

type RemoveOptionData = (value: string) => void

interface ComboboxContextValue {
  onSelect(value: string, data?: any): any
  getOptionData: GetOptionData
  addOptionData: AddOptionData
  removeOptionData: RemoveOptionData
}
