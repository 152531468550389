import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { ThemesCreateRoute_CreateThemeMutation, ThemesCreateRoute_CreateThemeMutationVariables } from '../__generated__/ThemesCreateRoute_CreateThemeMutation';
import { SlideOver } from '../components/SlideOver';
import { ThemeForm, ThemeValues } from '../components/ThemeForm';
import {useTranslation} from "react-i18next";

interface ThemesCreateRouteProps {
  companyId: string;
}

const ThemesCreateRoute: React.VFC<ThemesCreateRouteProps> = ({ companyId }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const parentId = searchParams.get('parent')
  const { t, i18n } = useTranslation();

  const [createTheme] = useMutation<ThemesCreateRoute_CreateThemeMutation, ThemesCreateRoute_CreateThemeMutationVariables>(gql`
    mutation ThemesCreateRoute_CreateThemeMutation($input: CreateThemeMutationInput!) {
      createTheme(input: $input) {
        theme {
          id
        }
      }
    }
  `)

  function onCancel() {
    navigate(`/${companyId}/themes`)
  }

  async function onSubmit(values: ThemeValues) {
    await createTheme({ variables: { input: { companyId, parentId, ...values } } })
    navigate(`/${companyId}/themes`)
  }

  return (
    <SlideOver title={t('create_theme')} onClose={onCancel}>
      <ThemeForm
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </SlideOver>
  )
}

export default ThemesCreateRoute;
