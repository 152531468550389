import * as React from 'react'
import tinydate from 'tinydate'

interface LocalTimeProps {
  dateTime: string
  format: string
}

const LocalTime = React.forwardRef<HTMLTimeElement, LocalTimeProps>(({ dateTime, format }, ref) => {
  const stamp = React.useMemo(() => tinydate(format), [format])
  const value = React.useMemo(
    () => stamp(new Date(dateTime)),
    [dateTime, stamp]
  )

  return (
    <time ref={ref} dateTime={dateTime} title={dateTime}>
      {value}
    </time>
  )
})

export default LocalTime
