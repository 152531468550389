import React from 'react';
import {Link, useLocation, useNavigate, useRoutes} from 'react-router-dom';
import { gql, useQuery } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { CompaniesRoute_CompanyListItem_company } from '../__generated__/CompaniesRoute_CompanyListItem_company';
import { CompaniesOverviewRouteQuery } from '../__generated__/CompaniesOverviewRouteQuery'
import { Topbar } from '../components/Topbar';
import { VFCwF } from '../types'
import CompaniesCreateRoute from "./CompaniesCreateRoute";
import DashboardRoute from "./DashboardRoute";
import {SearchBox} from "../components/Searchbox";
import {Button} from "../components/Button";
import {useTranslation} from "react-i18next";

interface CompanyListItemProps {
  company: CompaniesRoute_CompanyListItem_company
  userRole: string | null
}

const CompanyListItem: VFCwF<CompanyListItemProps> = ({ company }) => {
  const initials = React.useMemo(
    () =>
      company.name
        .split(' ')
        .slice(0, 2)
        .map((word) => word[0])
        .join('')
        .toUpperCase(),
    [company]
  )

  return (
    <div>
      <li className="bg-white shadow overflow-hidden rounded-md">
      <Link
        className="block hover:bg-gray-50"
        to={`/${company.id}`}
      >
        <div className="flex items-center p-4">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-200">
                <span className="text-sm font-medium leading-none text-blue-500">
                  {initials}
                </span>
              </span>
            </div>

            <div className="px-4">{company.name}</div>
          </div>

          <div className="ml-5 flex-shrink-0">
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </li>
    </div>
  )
}

CompanyListItem.fragments = {
  company: gql`
    fragment CompaniesRoute_CompanyListItem_company on Company {
      id
      name
    }
  `
}

const CompaniesOverviewRoute: React.VFC = () => {
  const navigate = useNavigate();

  function onSearchSelect(id: string) {
    navigate(`/${id}`)
  }

  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery<CompaniesOverviewRouteQuery>(gql`
    query CompaniesOverviewRouteQuery {
      companies {
        id
        ...CompaniesRoute_CompanyListItem_company
      }

      user: viewer {
        ...Topbar_user
      }
    }

    ${CompanyListItem.fragments.company}
    ${Topbar.fragments.user}
  `);

  if (error) {
    return <p>{t('error')}</p>
  }

  return (
    <div className="max-w-6xl mx-auto px-8">
      {loading ? (
        <p>{t('loading')}</p>
      ) : (
        <div>
          <header>
            <div className="flex items-center justify-between space-x-5 h-10">
              <h1 className="text-2xl font-semibold text-gray-900 truncate">
                {t('companies')}
              </h1>
              <div className="flex items-center space-x-3">
                <SearchBox
                  className="w-64"
                  options={data!.companies || []}
                  disabled={loading}
                  onSelect={onSearchSelect}
                />
              </div>
            </div>
          </header>
          <main className="mt-8">
            <ul className="space-y-4">
              {data!.companies.map((company) => (
                <CompanyListItem key={company.id} company={company} userRole={data!.user.role} />
              ))}
            </ul>
          </main>
        </div>
      )}
    </div>
  )
}

export default CompaniesOverviewRoute;
